import { call, put } from "redux-saga/effects";
import { ResponseError, api } from "../../../../services";
import { Creators } from "./index";

export default function* BeHomeHistoricCategoryRequest({ stayType }) {
  try {
    const response = yield call(api.getBeHomeHistoricCategory, stayType);

    yield put(Creators.BeHomeHistoricCategoryRequestSuccess(response));
  } catch (response) {
    const errorResponse = new ResponseError(response);
    errorResponse.alertMessage();
    yield put(Creators.HistoricListRequestFailure());
  }
}
