import React from "react";

import { ButtonText, FontStyles } from "../../../../components";

import { Visibility, VisibilityOff } from "@material-ui/icons";
import ReactCodeInput from "react-code-input";
import { Spacing } from "../../../../config";

function PasswordModal({ data }) {
  const [isPass, setIsPass] = React.useState(true);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ButtonText
        style={{
          padding: "8px",
          minWidth: "fit-content",
          minHeight: "fit-content",
          height: "fit-content",
          color: "black",
        }}
        fullWidth={false}
        onClick={() => setIsPass(!isPass)}
      >
        {isPass ? (
          <Visibility
            style={{
              alignSelf: "center",
              alignItems: "center",
              display: "flex",
              fontSize: "27px",
            }}
          />
        ) : (
          <VisibilityOff
            style={{
              alignSelf: "center",
              alignItems: "center",
              display: "flex",
              fontSize: "27px",
            }}
          />
        )}
      </ButtonText>
      <ReactCodeInput
        inputStyle={{
          fontFamily: FontStyles.bold22.fontFamily,
          margin: "4px",
          MozAppearance: "textfield",
          width: "44px",

          height: "60px",
          textAlign: "center",
          fontSize: "32px",
          color: "black",

          borderRadius: 0,
          backgroundColor: isPass ? "black" : "white",
          border: "3px solid rgba(0, 0, 0, 1)",
          pointerEvents: "none",
        }}
        value={data}
        type={isPass ? "password" : "text"}
        fields={5}
        name={"code"}
        inputMode={"numeric"}
      />
    </div>
  );
}

export default PasswordModal;
