import React from "react";
import Styles from "../styles/StyledFilter";
import { Select } from "../../../../components";
import { FormHolder } from "../../../../FormConfig";
import { Texts } from "../../../../config";
import { Grid } from "@material-ui/core";

function FiltersStudiosList({
  onStayTypeChange,
  onCategoryChange,
  categoryData,
  isFetchingCategoriesData,
  gridViewSelect,
  setGridViewSelected,
  listViewSelect,
  setListViewSelected,
}) {
  const title = Texts["pt-BR"].beHome.StudiosList;

  const modality = [
    {
      value: 0,
      label: "TODAS",
    },

    {
      value: 1,
      label: "HOSPEDAGEM",
    },

    {
      value: 2,
      label: "MORADIA",
    },
  ];

  const categoryDataSelectMap =
    categoryData !== null
      ? categoryData.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      : null;

  const allCategories = [{ value: 0, label: "TODAS" }];

  const studiosType =
    categoryDataSelectMap !== null
      ? allCategories.concat(categoryDataSelectMap)
      : allCategories;

  return (
    <FormHolder>
      <Styles.CardContainer>
        <Grid container spacing={1} direction="row">
          <Grid item xs={12} sm={12} md={12} lg={5}>
            <Styles.CardContent>
              <Styles.CardTitle>{title.filters.modality}</Styles.CardTitle>
              <Select
                containerStyles={{ marginTop: 8 }}
                name="staySubscriptionType"
                disbleError
                options={modality}
                StyledInput
                onChange={onStayTypeChange}
                defaultValue={0}
                disableBorder
                small
              />
            </Styles.CardContent>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Styles.CardContent>
              <Styles.CardTitle>{title.filters.studiosType}</Styles.CardTitle>
              <Select
                containerStyles={{ marginTop: 8 }}
                name="studiosType"
                disbleError
                options={studiosType}
                onChange={onCategoryChange}
                defaultValue={0}
                disableBorder
                small
              />
            </Styles.CardContent>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={3}>
            <Styles.CardContent $last>
              <Styles.CardTitle>{title.filters.visualization}</Styles.CardTitle>
              <Styles.GridViewIcon
                onClick={() =>
                  setGridViewSelected(true) & setListViewSelected(false)
                }
                $selected={gridViewSelect}
              />
              <Styles.ListViewIcon
                onClick={() =>
                  setListViewSelected(true) & setGridViewSelected(false)
                }
                $selected={listViewSelect}
              />
            </Styles.CardContent>
          </Grid>
        </Grid>
      </Styles.CardContainer>
    </FormHolder>
  );
}

export default FiltersStudiosList;
