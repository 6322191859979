import React from "react";
import { Pagination, Table } from "../../../../components";
import { Texts } from "../../../../config";
import { Forward } from "@material-ui/icons";
import TransferModal from "./TransferModal";
import CancelModal from "./CancelModal";
import ReturnReservationModal from "./ReturnReservationModal";
import HistoricCheckinModal from "./HistoricCheckinModal";
//import GuestRecordModal from "./GuestRecordModal";
import { customModal } from "../../../modals/utils";
import { Payments, PendingActions } from "@mui/icons-material";
import DropdownActionSelect from "../../../../components/buttons/DropdownActionSelect";
import Styles from "../styles/ModalStyles";
//import { Filters } from "../../../../lib";//

function HistoricTable({ data, loading, page, setPage, reload, list, sort }) {
  const texts = Texts["pt-BR"].beHome.HistoricList;
  const { StayTypes } = Texts["pt-BR"].beHome;
  const returnReservationText = Texts["pt-BR"].beHome.HistoricList.ReturnModal;
  const HistoricCheckinText =
    Texts["pt-BR"].beHome.HistoricList.HistoricCheckinModal;
  //const GuestRecordText = Texts["pt-BR"].beHome.HistoricList.guestRecordModal;

  /*function displayDate(checkIn, checkOut) {
    return `${Filters.fixDateToLabel(checkIn)} ${
      texts.until
    } ${Filters.fixDateToLabel(checkOut)}`;
  }*/

  const handleOpenTransferModal = (userData) => {
    const text = Texts["pt-BR"].beHome.HistoricList.ModalTransfer;
    customModal.setInfos(
      <Forward style={{ marginRight: 10, color: "black" }} />,
      text.titleTransfer,
      [text.text1],
      null,
      null,
      <TransferModal tableData={userData} />
    );
  };

  const handleReturnReservationModal = (userData) => {
    customModal.setInfos(
      <Payments style={{ marginRight: 10, color: "black" }} />,
      <Styles.Title>{returnReservationText.title}</Styles.Title>,
      [returnReservationText.text1],
      null,
      null,
      <ReturnReservationModal
        //InfoCancellationRequest={InfoCancellationRequest}
        tableData={userData}
        //InfoCancellationData={InfoCancellationData}
      />
    );
  };

  const handleHistoricCheckinModal = (userData) => {
    customModal.setInfos(
      <PendingActions style={{ marginRight: 10, color: "black" }} />,
      <Styles.Title>{HistoricCheckinText.title}</Styles.Title>,
      null,
      null,
      null,
      <HistoricCheckinModal
        //InfoCancellationRequest={InfoCancellationRequest}
        tableData={userData}
        //InfoCancellationData={InfoCancellationData}
      />
    );
  };

  /*const handleGuestRecordModal = (userData) => {
    customModal.setInfos(
      <AccountBox style={{ marginRight: 10, color: "black" }} />,
      <Styles.Title>{GuestRecordText.title}</Styles.Title>,
      null,
      null,
      null,
      <GuestRecordModal
        //InfoCancellationRequest={InfoCancellationRequest}
        checkInId={userData}
        //InfoCancellationData={InfoCancellationData}
      />
    );
  };*/

  return (
    <>
      <Table
        sortContent={() => {}}
        id="historicTable"
        headers={texts}
        data={data.content}
        totalPage={50}
        withGroup
        firstDivision={5}
        secondDivision={5}
        loading={loading}
        renderItemColumns={(item) => [
          item.guest.name,
          item.stay.checksDate,
          StayTypes[item.stay.stayType],
          item.status,

          <DropdownActionSelect
            options={[
              {
                action: () => handleReturnReservationModal(item),
                icon: () => <Payments />,
                name: returnReservationText.title,
              },
              {
                action: () => handleHistoricCheckinModal(item),
                icon: () => <PendingActions />,
                name: HistoricCheckinText.title,
              },
              /*{
                action: () => handleGuestRecordModal(item),
                icon: () => <AccountBox />,
                name: GuestRecordText.buttomName,
              },*/
            ]}
          />,
          <CancelModal tableData={item} />,
          <Styles.Container>
            <Styles.ButtonTableModal
              $defaultColor={1}
              onClick={() => handleOpenTransferModal(item)}
              endIcon={<Forward />}
            >
              {Texts["pt-BR"].beHome.HistoricList.TransferButton}
            </Styles.ButtonTableModal>
          </Styles.Container>,
          /*<CanceledModal />,*/
        ]}
      />

      <Pagination page={page} setPage={setPage} totalPages={data.totalPages} />
    </>
  );
}

export default HistoricTable;
