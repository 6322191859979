import React from "react";
import HomePage from "../features/homePage/containers/HomePage";

function Home() {
  return (
    <>
      <HomePage />
    </>
  );
}

export default Home;
