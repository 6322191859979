import { call, put } from "redux-saga/effects";
import { ResponseError, api } from "../../../../services";
import { Alerts } from "../../../../lib";
import { Texts } from "../../../../config";
import { Creators } from "./index";

export default function* BehomeHistoricCancel({
  stayType,
  StaySubscriptionId,
  data,
}) {
  console.log("CANCELOU !!!!", stayType, StaySubscriptionId, data);
  const textAlertSucess =
    Texts["pt-BR"].beHome.RequestedReservations.ModalcancelReserve;
  try {
    yield call(api.BehomeHistoricCancel, stayType, StaySubscriptionId, {
      cancelReason: data?.cancelReason,
    });
    console.log("CANCELOU !!!!", stayType, StaySubscriptionId);
    Alerts.alertSuccess(textAlertSucess.PopupText);
    //yield put(Creators.HistoricListRequest());
  } catch (response) {
    const errorResponse = new ResponseError(response);
    errorResponse.alertMessage();
    yield put(Creators.HistoricListRequestFailure());
  }
}
