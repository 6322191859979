import { call, put } from "redux-saga/effects";
import { ResponseError, api } from "../../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../../lib";
import { Texts } from "../../../../config";

export default function* ReverseReservation({ behomeShortStaySubscriptionId }) {
  const textAlertSucess =
    Texts["pt-BR"].beHome.RequestedReservations.ModalTransferReserve;

  console.log("REQUEST!!!", behomeShortStaySubscriptionId);
  try {
    yield call(
      api.BehomeHistorcReturnReservation,
      behomeShortStaySubscriptionId
    );
    console.log("api!!!", behomeShortStaySubscriptionId);
    Alerts.alertSuccess(textAlertSucess.PopupText);
    yield put(Creators.HistoricListRequestFinish());
    yield put(Creators.HistoricListRequest());
  } catch (response) {
    const errorResponse = new ResponseError(response);
    errorResponse.alertMessage();
    yield put(Creators.HistoricListRequestFailure());
  }
}
