import React, { useState } from "react";
import Styles from "../styles/Styles";

import { Grid } from "@material-ui/core";

import {
  ButtonText,
  CustomText,
  Input,
  InputDate,
  Select,
} from "../../../components";
import fonts from "../../../config/fonts";
import { FormHolder } from "../../../FormConfig";
import filters from "../../../lib/Filters";

function FiltersComponent({ onSubmit, loading, lastFilter }) {
  const [initDate, setInitDate] = useState(null);
  const [formRef, setFormRef] = React.useState(null);

  const clearData = async () => {
    formRef?.clearAllValues(false);
    onSubmit({ startDate: null, endDate: null });
  };

  const sendData = async () => {
    const formData = await formRef?.testErrorsAndReturnData();

    if (!Boolean(formData?.hasError)) {
      let adjustData = {
        startDate:
          formData?.data?.startDate && formData?.data?.startDate !== "null"
            ? filters.dataToYYYYMMDD(formData?.data?.startDate)
            : null,
        endDate:
          formData?.data?.endDate && formData?.data?.endDate !== "null"
            ? filters.dataToYYYYMMDD(formData?.data?.endDate)
            : null,
      };

      onSubmit(adjustData);
    }
  };

  const endDate = new Date();
  const startDate = new Date();
  startDate.setDate(endDate.getDate() - 14);
  return (
    <>
      <Styles.Container>
        <CustomText
          style={{ paddingBottom: "14px" }}
          fontFamily={fonts.bold}
          fontSize={2}
        >
          Filtrar Dashboard
        </CustomText>
        <FormHolder
          alignItems="flex-end"
          formRef={setFormRef}
          onSubmit={() => {}}
        >
          <Grid container spacing={1}>
            {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={8}>
              <Select
                name="vertical"
                inputLabel="Vertical"
                placeholder="Selecione uma vertical"
                defaultValue="Behome"
                options={[{ label: "Bewiki Behome", value: "Behome" }]}
              />
            </Grid> */}
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12}>
                <CustomText
                  style={{ paddingBottom: "9px" }}
                  fontSize={1.75}
                  fontFamily={fonts.semibold}
                >
                  Período
                </CustomText>
              </Grid>

              <Grid item xs={12} sm={5}>
                <InputDate
                  noMargin
                  name="startDate"
                  defaultValue={startDate}
                  onChange={(e, value) => {
                    if (
                      formRef?.getFieldValue("endDate") &&
                      formRef?.getFieldValue("endDate") < value
                    ) {
                      setInitDate(value);

                      formRef?.setValue("endDate", null);
                    } else {
                      setInitDate(value);
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={2}
                style={{
                  justifyContent: "center",

                  display: "flex",
                }}
              >
                <CustomText
                  style={{ paddingBottom: "20px" }}
                  fontSize={1.75}
                  fontFamily={fonts.medium}
                >
                  até
                </CustomText>
              </Grid>
              <Grid item xs={12} sm={5}>
                <InputDate
                  noMargin
                  defaultValue={endDate}
                  minDate={initDate}
                  name="endDate"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  gap: "8px",
                }}
              >
                <Styles.ButtonClear
                  fullWidth={false}
                  onClick={clearData}
                  loading={loading}
                  disabled={loading}
                  name="clearUserFilter"
                >
                  Limpar
                </Styles.ButtonClear>
                <Styles.ButtonFilter
                  fullWidth={false}
                  onClick={sendData}
                  loading={loading}
                  disabled={loading}
                  name="submitUserFilter"
                >
                  Aplicar Filtros
                </Styles.ButtonFilter>
              </div>
            </Grid>
          </Grid>
        </FormHolder>
      </Styles.Container>
    </>
  );
}

export default FiltersComponent;
