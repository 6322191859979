import { call, put } from "redux-saga/effects";
import { ResponseError, api } from "../../../services";
import { Creators } from "./index";

export default function* DashDailyAverageGraph({ data }) {
  try {
    const response = yield call(api.getDashDailyAverageGraph, data); //TODO trocar rota
    if (response.ok) {
      yield put(Creators.dashDailyAverageGraphSuccess(response.data));
    }
  } catch (response) {
    const errorResponse = new ResponseError(response);
    errorResponse.alertMessage();
    yield put(Creators.dashDailyAverageGraphFailure());
  }
}
