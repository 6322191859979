import React from "react";
import {
  FormControlLabel,
  FormControl,
  FormHelperText,
  Checkbox,
  withTheme,
} from "@material-ui/core";
import { formConnector } from "../../FormConfig";
import { Colors, Spacing } from "../../config";
import styled from "styled-components";

const StyledCheckBox = styled(Checkbox)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    marginRight: spacing(1),
    padding: 0,
    width: 24,
    height: 24,
    color: Colors.white1,
    border: "solid 2px",
    borderColor: colors.black,
    fontSize: 2,
    borderRadius: "3px",
    "&.Mui-checked": {
      color: Colors.black,
    },
  };
});

function CheckboxComponent(props) {
  const { label, required, disableError } = props;

  const { value, error, setConfigs, ref } =
    formConnector.useBooleanValue(props);

  return (
    <FormControl required={required} error={error}>
      <FormControlLabel
        control={<StyledCheckBox checked={value} inputRef={ref} />}
        onChange={(e, v) => {
          setConfigs(v);
        }}
        label={label}
        labelPlacement="end"
      />
      {disableError ? null : (
        <FormHelperText>{error ? error : " "}</FormHelperText>
      )}
    </FormControl>
  );
}

export default withTheme(CheckboxComponent);
