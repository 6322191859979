import React from "react";

import { Input } from "..";
import { Visibility, VisibilityOff } from "@material-ui/icons";

class InputPassword extends React.Component {
  state = {
    showPassword: false,
  };
  render() {
    return (
      <Input
        {...this.props}
        withHide={() => {
          this.setState({ showPassword: !this.state.showPassword });
        }}
        type={this.state.showPassword ? "text" : "password"}
        changeIcon={
          !this.state.showPassword ? <Visibility /> : <VisibilityOff />
        }
      />
    );
  }
}
export default InputPassword;
