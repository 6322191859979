import { call, put } from "redux-saga/effects";
import { ResponseError, api } from "../../../../services";
import { Creators } from "./index";
export default function* BehomeHistoricStayRequest({ behomeStayId }) {
  try {
    console.log("behomeStayId", behomeStayId);
    const response = yield call(api.getBehomeHistoricStay, behomeStayId);
    console.log("getBehomeHistoricStay", response);
    yield put(Creators.BehomeHistoricStayRequestSuccess(response));
  } catch (response) {
    const errorResponse = new ResponseError(response);
    errorResponse.alertMessage();
    yield put(Creators.HistoricListRequestFailure());
  }
}
