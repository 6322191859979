import React from "react";
import { Switch, Route } from "react-router-dom";
import { PagesConfig } from "../config";
import Pages from "../pages";
import { Creators as globalCreators } from "../features/globalReduxSagas";
import { connect } from "react-redux";
import { Styles } from "./styles";
import { Menu, PageGuide, Header } from "../features";
import { CircularProgress } from "@mui/material";
function Routes({
  isLoggedIn,
  isFetching,
  getStatus,
  pathname,
  isFetchingStart,
}) {
  const mount = React.useCallback(() => {
    getStatus();
  }, [getStatus]);
  React.useEffect(mount, [mount]);

  const [isOpen, openMenu] = React.useState(false);
  const [menuHeight, setHeight] = React.useState(null);
  React.useLayoutEffect(() => {
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      const fh = document.getElementById("footer")?.offsetHeight;
      let totalH = 0;
      if (mh) {
        totalH += mh;
      }
      if (fh) {
        totalH += fh;
      }

      setHeight(totalH);
    }
  }, [menuHeight]);

  const randKey = () => (Math.random() + 1).toString(36).substring(7);

  return isFetchingStart ? (
    <Styles.InitialBackground
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        padding: "80px",
      }}
      $didMount={!isFetching || !isFetchingStart}
    >
      <CircularProgress />
    </Styles.InitialBackground>
  ) : isLoggedIn ? (
    <Styles.InitialBackground $didMount={!isFetching || !isFetchingStart}>
      <Menu id="header-menu" isOpen={isOpen} openMenu={openMenu} />
      <Styles.LoggedContainer id="scroll-container">
        <Header isOpen={isOpen} openMenu={() => openMenu(!isOpen)} />
        <Switch
          style={{
            height: menuHeight ? `calc(100vh - ${menuHeight}px)` : "86vh",
          }}
        >
          {PagesConfig.logged.map((item, index) => {
            if (item.pages) {
              return item.pages.map((page, index) => {
                const Component = Pages[page.name];
                if (Component) {
                  return (
                    <Route
                      key={randKey()}
                      exact={true}
                      path={page.path}
                      component={() => (
                        <PageGuide
                          id={index + "vertical"}
                          vertical={page?.name}
                        >
                          <Component />
                        </PageGuide>
                      )}
                    />
                  );
                } else {
                  return null;
                }
              });
            }
            return null;
          })}
        </Switch>
      </Styles.LoggedContainer>
    </Styles.InitialBackground>
  ) : (
    <Styles.InitialBackground $didMount={!isFetching || !isFetchingStart}>
      <Switch>
        {PagesConfig.notLogged.map((item, index) => (
          <Route
            key={randKey()}
            exact={true}
            path={item.path}
            component={Pages[item.name]}
          />
        ))}
      </Switch>
    </Styles.InitialBackground>
  );
}

function mapStateToProps({ global, router }) {
  const { isLoggedIn, isFetching, isFetchingStart } = global;
  const { pathname } = router.location;
  return {
    isLoggedIn,
    pathname,
    isFetching,
    isFetchingStart,
  };
}

function mapDispatchToProps(dispatch) {
  const { getGlobalStatus } = globalCreators;
  return {
    getStatus: () => dispatch(getGlobalStatus()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Routes));
