import React from "react";
import Styles from "../styles/StyledDetails";
import { Grid } from "@material-ui/core";
import StudioDataInput from "./StudioDataInput";
import { FormHolder } from "../../../../FormConfig";
import { Texts } from "../../../../config";
import CurrentReservationInput from "./CurrentReservationInput";

import { connect } from "react-redux";
import { Creators } from "../reduxSagas";

function StudioListDetails({
  changeScreen,
  studioListData,
  BehomeChangeStatusAvaliable,
  BehomeChangeStatusCleaning,
  isFetchingStudioListData,
}) {
  const texts = Texts["pt-BR"].beHome.StudioDetailsList;
  return (
    <>
      <FormHolder>
        <Styles.HeaderContainer onClick={changeScreen}>
          <Grid
            container
            item
            xs={3}
            direction="row"
            alignItems="center"
            justify="flex-start"
          >
            <Styles.GoBackIcon />
            <Styles.Title $principal $first>
              {studioListData.studioName + ": " + studioListData.externalId}
            </Styles.Title>
          </Grid>
          <Styles.Line />
        </Styles.HeaderContainer>

        <Styles.Title $first>{texts.title[0]}</Styles.Title>
        <StudioDataInput studioListData={studioListData} />
        {/*<Styles.Title>{texts.title[1]}</Styles.Title>*/}
        <CurrentReservationInput
          studioListData={studioListData}
          BehomeChangeAvaliable={BehomeChangeStatusAvaliable}
          BehomeChangeCleaning={BehomeChangeStatusCleaning}
          isFetching={isFetchingStudioListData}
        />
        {/*<Styles.Title>{texts.title[2]}</Styles.Title>*/}
        {/*<ReservationTable />*/}
      </FormHolder>
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  const { BehomeChangeStatusAvaliable, BehomeChangeStatusCleaning } = Creators;
  return {
    BehomeChangeStatusAvaliable: function (BehomeStayId) {
      return dispatch(BehomeChangeStatusAvaliable(BehomeStayId));
    },
    BehomeChangeStatusCleaning: function (BehomeStayId) {
      return dispatch(BehomeChangeStatusCleaning(BehomeStayId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudioListDetails);
