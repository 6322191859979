import { Grid } from "@material-ui/core";
import { AccountBox, LocalActivity, Person } from "@material-ui/icons";
import React from "react";
import { ButtonContained } from "../../../../components";
import InputCPF from "../../../../components/inputs/InputCPF";
import { Texts } from "../../../../config";
import { FormHolder } from "../../../../FormConfig";
import { Filters } from "../../../../lib";
import { customModal } from "../../../modals/utils";
import { CouponCardStyles as Styles } from "../styles";

export default function CouponCard({
  value,
  maxUses,
  currentUses,
  id,
  goNext,
  code,
  sendingCouponRegisterRequest,
  CouponRegisterCPFRequest,
}) {
  const texts = Texts["pt-BR"].beMarket.couponList.allCoupons.couponCard;
  const successModalTexts =
    Texts["pt-BR"].beMarket.couponList.allCoupons.couponCard
      .redeemCouponSuccess;

  const showRedeemCouponModalSuccess = () => {
    customModal.setInfos(successModalTexts.title, [successModalTexts.text], {
      label: successModalTexts.continue,
      onClick: customModal.close,
    });
  };

  const showRedeemCouponModal = () => {
    customModal.setInfos(
      <LocalActivity style={{ marginRight: 5 }} />,
      texts.redeemCouponModal.title,
      [
        <Styles.RedeemModalCouponName>
          {texts.coupon} {code} {Filters.convertMoneyTextMask(value)}
        </Styles.RedeemModalCouponName>,
      ],
      null,
      null,
      <>
        <Styles.RedeemModalContent>
          <FormHolder
            onSubmit={({ cpf }) =>
              CouponRegisterCPFRequest(id, cpf, showRedeemCouponModalSuccess)
            }
          >
            <InputCPF inputLabel={"CPF"} name={"cpf"} defaultBorder />
            <Grid container spacing={1} direction="row" justify="flex-end">
              <Styles.ButtonOutlinedModal
                $defaultBackButtom
                onClick={customModal.close}
              >
                {texts.redeemCouponModal.back}
              </Styles.ButtonOutlinedModal>
              <Styles.ButtonOutlinedModal
                type="submit"
                loading={sendingCouponRegisterRequest}
                disabled={sendingCouponRegisterRequest}
              >
                {texts.redeemCouponModal.confirm}
              </Styles.ButtonOutlinedModal>
            </Grid>
          </FormHolder>
        </Styles.RedeemModalContent>
      </>
    );
  };

  return (
    <Styles.Card>
      <Styles.Content>
        <Grid item xs={12} sm={12} md={12} lg={12} container direction="row">
          <Styles.Header onClick={goNext}>
            <LocalActivity style={{ marginRight: "-5px" }} />
            {texts.coupon} {code} {Filters.convertMoneyTextMask(value)}
            <Grid
              item
              xs={12}
              sm={6}
              md={12}
              lg={4}
              container
              direction="row"
              justify="flex-end"
            >
              {currentUses}/{maxUses}
              <Person />
            </Grid>
          </Styles.Header>
        </Grid>
      </Styles.Content>

      <Styles.Content>
        <Grid container spacing={0} direction="row" justify="flex-end">
          <Grid item xs={7} sm={6} md={8} lg={6}>
            <Styles.RedeemButton
              endIcon={<AccountBox />}
              onClick={showRedeemCouponModal}
            >
              {texts.redeemForCPF}
            </Styles.RedeemButton>
          </Grid>
        </Grid>
      </Styles.Content>
    </Styles.Card>
  );
}
