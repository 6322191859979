import { call, put } from "redux-saga/effects";
import { ResponseError, api } from "../../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../../lib";
import { Texts } from "../../../../config";

export default function* BehomeHistoricTransfer({
  stayType,
  StaySubscriptionId,
  BehomeStayId,
}) {
  //console.log("########", stayType, StaySubscriptionId, BehomeStayId);
  const textAlertSucess =
    Texts["pt-BR"].beHome.RequestedReservations.ModalTransferReserve;
  try {
    yield call(
      api.BehomeHistoricTransfer,
      stayType,
      StaySubscriptionId,
      BehomeStayId
    );
    //console.log("MANDOU!!!!!", stayType, StaySubscriptionId, BehomeStayId);
    Alerts.alertSuccess(textAlertSucess.PopupText);
    yield put(Creators.HistoricListRequest());
  } catch (response) {
    const errorResponse = new ResponseError(response);
    errorResponse.alertMessage();
    yield put(Creators.HistoricListRequestFailure());
  }
}
