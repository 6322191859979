import styled from "styled-components";
import { ButtonContained, ButtonText, FontStyles } from "../../../components";
import { Colors, MediaQueries } from "../../../config";

const Container = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    padding: spacing(2.75),
    background: Colors.white0,
    width: "100%",
    height: "auto",
  };
});

const Background = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    padding: spacing(2),
    backgroundColor: "#F3F3F3",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  };
});

const RowContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "4px",
  };
});

const ButtonFilter = styled(ButtonContained)(
  ({ theme, $withValue, $withError }) => {
    const { spacing } = theme;

    return {
      borderRadius: 0,
      paddingBlock: spacing(2),
      paddingInline: spacing(2.75),
      ...FontStyles.semibold14,
      textTransform: "none",
      backgroundColor: "black",
    };
  }
);
const ButtonClear = styled(ButtonText)(({ theme, $withValue, $withError }) => {
  const { spacing } = theme;

  return {
    borderRadius: 0,
    paddingBlock: spacing(2),
    paddingInline: spacing(2.75),
    ...FontStyles.semibold14,
    textTransform: "none",
  };
});

const ButtonType = styled(ButtonText)(({ theme, $on }) => {
  const { spacing } = theme;

  return {
    borderRadius: 0,
    paddingBlock: spacing(1.8),

    ...FontStyles.semibold14,
    textTransform: "none",
    opacity: $on ? 0.6 : 1,
  };
});

const EmptyContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    height: "100%",
    padding: spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
});

const ContainerGraph = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",

    [MediaQueries.smDown]: {
      display: "none",
    },
  };
});

const ContainerGraphMobile = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    display: "none",
    [MediaQueries.smDown]: {
      display: "flex",
    },
  };
});

const Styles = {
  Container,

  Background,
  RowContainer,
  ButtonClear,
  ButtonFilter,
  ButtonType,
  EmptyContainer,
  ContainerGraphMobile,
  ContainerGraph,
};

export default Styles;
