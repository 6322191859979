import styled from "styled-components";
import { FontStyles } from "../../../components";
import { Button, Checkbox, IconButton } from "@material-ui/core";
import { Colors, Fonts } from "../../../config";
import Logos from "../../../config/logos";
import { MediaQueries } from "../../../config";
import Icons from "../../../config/icons";

const Container = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  overflow: "hidden",
  backgroundColor: Colors.white3,
}));

const Content = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    right: 0,
    width: "32rem",
    maxWidth: `calc(100% - ${spacing(2)}px)`,
    maxHeight: `calc(100% - ${spacing(3)}px)`,
    padding: spacing(4),
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    backgroundColor: Colors.white1,
    borderRadius: 5,
    margin: "auto",
    marginTop: spacing(2),
    marginBottom: spacing(2),
    //overflowY: "auto",
  };
});

const HeaderContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    width: "32rem",
    maxWidth: `calc(100% - ${spacing(2)}px)`,
    maxHeight: `calc(100% - ${spacing(3)}px)`,
    padding: spacing(1),
    background: "none",
    borderRadius: 5,

    marginBottom: spacing(5),
  };
});

const BackButton = styled(IconButton)(({ theme }) => {
  return {
    width: "auto",
    marginRight: "60px",
    "&:hover": {
      background: "none",
    },
  };
});

const ForgotPasswordButton = styled(Button)(({ theme }) => {
  return {
    "&:hover": {
      background: "none",
    },
    textDecoration: "underline",
    fontStyle: "italic",
    textTransform: "none",
    marginTop: "-25px",
  };
});

const LogoContainer = styled.div(({ theme, $defaultjustify }) => {
  return {
    width: "217px",
    height: "auto",
    alignSelf: "center",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: $defaultjustify ? "center" : null,
    alignContent: "flex-start",
    gap: 2,
  };
});

const Logo = styled(Logos.bewikiLogo)(({ theme }) => {
  return {
    width: "70%",
    height: "70%",
  };
});

const TokenFieldContainer = styled.div(({ theme }) => {
  return {
    alignSelf: "center",
    marginBottom: "23px",
  };
});

const VersionContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    alignSelf: "center",
    marginBottom: spacing(0),
    marginTop: spacing(3),
  };
});

const TopLeftShape = styled(Icons.BemarketShape)((props) => {
  return {
    maxWidth: "40%",
    maxHeight: "90vh",
    position: "absolute",
    right: "80%",
    top: 0,
    transform: `translate(40%, -60%)`,
  };
});

const MiddleLeftShape = styled(Icons.BemobiShape)((props) => {
  return {
    maxWidth: "50%",
    maxHeight: "90vh",
    position: "absolute",
    right: "100%",
    top: "40%",
    transform: `translate(40%, -40%)`,
  };
});

const LeftShapeBottom = styled(Icons.behomeShape)(() => {
  return {
    maxWidth: "70%",
    maxHeight: "90vh",
    position: "absolute",
    right: "95%",
    top: "100%",
    transform: `translate(60%, -50%)`,

    [MediaQueries.smDown]: {
      maxWidth: "65%",
      position: "absolute",
      left: "-60%",
      top: "100%",
      transform: `translate(60%, -50%)`,
    },
  };
});

const TopRightShape = styled(Icons.BeparkShape)(() => {
  return {
    maxWidth: "80%",
    maxHeight: "90vh",
    position: "absolute",
    top: "-25%",
    right: "2%",
    transform: ` translate(10%, -5%) rotate(${-10}deg)`,
    [MediaQueries.smDown]: {
      transform: `translate(20%, -1%) rotate(${50}deg)`,
      width: "75%",
      top: "-29%",
      right: "-10%",
    },
  };
});

const MiddleRightShape = styled(Icons.BecareShape)(() => {
  return {
    maxWidth: "50%",
    maxHeight: "90vh",
    position: "absolute",
    top: "30%",
    right: 0,
    transform: `translate(60%, -50%)`,
    /*[MediaQueries.smDown]: {
      //abaixo de sm que é xs
      width: "15%",
    },*/
  };
});

const BottomRightShape = styled(Icons.BeworkShape)(() => {
  return {
    maxWidth: "100%",
    maxHeight: "90vh",
    position: "absolute",
    top: "54%",
    right: 0,
    transform: `translate(25%, 33%)`,
    /*[MediaQueries.smDown]: {
      //abaixo de sm que é xs
      width: "90%",
    },*/
  };
});

const Subtitle = styled.p(() => {
  return {
    color: "black",
    ...FontStyles.semibold16,
    opacity: 0.88,
    margin: 0,
  };
});

const Link = styled.a(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    color: colors.text.primary,
    ...FontStyles.roman14,
    textDecoration: "underline",
    cursor: "pointer",
    transition: ".2s",
    alignSelf: "flex-end",
    marginBottom: spacing(3),
    ":hover": {
      color: colors.text.secondary,
    },
  };
});

const Version = styled.p(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    alignSelf: "center",
    marginTop: spacing(4),
    color: colors.text.primary,
    fontFamily: Fonts.bold,
    fontSize: Fonts.sizes.small,
    ...FontStyles.italic14,
  };
});

const StyledButton = styled(Button)(({ theme, fullWidth }) => {
  const { spacing, palette: colors } = theme;
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: fullWidth ? "100%" : "fit-content",
    margin: spacing(0),
    padding: spacing(1.1, 1),
    fontFamily: Fonts.semibold,
    fontSize: 14,
    backgroundColor: colors.action.backgroundHover,
    color: "white",
    flexShrink: 0,
    overflow: "hidden",
    borderRadius: "20px",
    ".MuiButton-startIcon": {
      fill: "colors.primary.contrastText",
      width: 17,
    },
    "&:hover": {
      backgroundColor: colors.action.disabledBackground,
      color: colors.action.active,
    },
  };
});

const StyledCheckBox = styled(Checkbox)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    marginRight: spacing(1),
    padding: 0,
    width: 24,
    height: 24,
    color: Colors.white1,
    border: "solid 2px",
    borderColor: colors.black,
    fontSize: 2,
    borderRadius: "3px",
    "&.Mui-checked": {
      color: Colors.black,
    },
  };
});

const Styles = {
  Container,
  HeaderContainer,
  BackButton,
  ForgotPasswordButton,
  Content,
  LogoContainer,
  VersionContainer,
  Logo,
  Version,
  Subtitle,
  Link,
  BottomRightShape,
  LeftShapeBottom,
  StyledButton,
  TokenFieldContainer,
  MiddleLeftShape,
  MiddleRightShape,
  TopLeftShape,
  TopRightShape,
  StyledCheckBox,
};

export default Styles;
