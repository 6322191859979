import { Filters } from "../../lib";
export default function getBeHomeHistoric(response) {
  if (response.status === 204) {
    return { data: { content: [], totalPages: 0 } };
  }
  let data = response.data.content.map((item, i) => {
    return {
      guest: {
        id: item.id,
        name: item.name + " " + item.lastName,
        stayType: item.staySubscriptionType,
      },
      stay: {
        stayId: item.behomeStay.id,
        behomeStay: item.behomeStay.name,
        stayType: item.behomeStay.stayType,
        checksDate:
          Filters.fixDateToLabel(item.checkIn) +
          " " +
          "até" +
          " " +
          Filters.fixDateToLabel(item.checkOut),
        checkInDate: item.checkIn,
        status: item.behomeStay.status,
      },
      category: {
        id: item.id,
        price: item.price,
      },
      status: item.status,
      checks: {
        id: item.checks.id,
        checkDateTime: item.checks.checkDateTime,
      },
      cancelDate: item.cancelDate,
    };
  });
  return {
    ...response,
    data: { content: data, totalPages: response.data.totalPages },
  };
}
