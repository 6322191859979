import { call, put } from "redux-saga/effects";
import { LocalStorage, SessionStorage } from "../../lib";
import { Creators } from ".";
import { api } from "../../services";

export default function* getGlobalStatus() {
  const isLoggedIn = SessionStorage.getItem("isLoggedIn");
  if (isLoggedIn) {
    yield put(Creators.getUserInfosRequest());
    yield put(Creators.setIsLoggedIn(true));
    yield put(Creators.getGlobalStatusSuccess());
  } else {
    const refreshToken = LocalStorage.getItem("refreshToken");
    if (refreshToken) {
      const response = yield call(api.getNewToken, refreshToken);
      if (!response.ok) {
        yield LocalStorage.removeItem("refreshToken");
        yield put(Creators.getGlobalStatusFailure());
      } else {
        const newRefreshToken = response.data.token;
        yield LocalStorage.setItem("refreshToken", newRefreshToken);
        yield put(Creators.getUserInfosRequest());
        yield put(Creators.setIsLoggedIn(true));
        yield put(Creators.getGlobalStatusSuccess());
      }
    } else {
      yield LocalStorage.removeItem("refreshToken");
      yield put(Creators.getGlobalStatusFailure());
    }
  }
}
