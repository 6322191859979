export default function getCashBackList(response) {
  if (response.status === 204) {
    return { data: { content: [], totalPages: 0 } };
  }
  let data = response.data.content.map((item, i) => {
    return {
      id: item.id,
      name: item.user.name,
      lastName: item.user.lastName,
      purchaseValue: item.transaction.value,
      cashbackValue: item.value,
      sector: {
        id: item.sector.id,
        verticalName: item.sector.name,
      },
      infoDate: item.createdAt,
    };
  });
  return {
    ...response,
    data: { content: data, totalPages: response.data.totalPages },
  };
}
