import { Box, Button, Grid } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

export default function CustomerDetailsDocumentPictureScreen({
  goNext,
  goPrevious,
  customerDetailsDocumentPictureData,
}) {
  return (
    <Box m={"auto"}>
      <Grid container>
        <Grid item xs={3} sm={1} md={5} lg={1}>
          <Button onClick={goPrevious}>
            <ChevronLeft />
          </Button>
        </Grid>
        <Grid container alignItems="center" item xs={6} sm={1} md={5} lg={10}>
          <img
            src={
              "data:image/png;base64," + customerDetailsDocumentPictureData[0]
            }
            style={{ width: "100%", height: "100%" }}
            alt=""
          />
        </Grid>
        <Grid item xs={3} sm={1} md={5} lg={1}>
          <Button onClick={goNext}>{<ChevronRight />}</Button>
        </Grid>
      </Grid>
    </Box>
  );
}
