import { call, put } from "redux-saga/effects";
import { Creators } from "./index";
import { api, ResponseError } from "../../../../services";

export default function* GuestRecordDetailsRequest({ checkInId }) {
  try {
    console.log("##########", checkInId);
    const response = yield call(api.getBehomeHistoricGuestSheet, checkInId);
    console.log("##########", response);
    yield put(Creators.GuestRecordDetailsRequestSuccess(response.data?.data));
  } catch (response) {
    const errorResponse = new ResponseError(response);
    errorResponse.alertMessage();
    yield put(Creators.GuestRecordDetailsRequestFailure());
  }
}
