import React from "react";
import ForgotPasswordContent from "../components/ForgotPasswordContent";
import LoginContent from "../components/LoginContent";
import { Styles } from "../styles";
import Title from "../../../components/strings/Title";
import { version } from "../../../../package.json";
import { Grid } from "@material-ui/core";

function Login() {
  const { REACT_APP_ENV } = process.env;
  const versionAndEnvironment = `${version} ${REACT_APP_ENV}`;
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const toggleForgotPassword = () => {
    setForgotPassword(!forgotPassword);
  };

  return (
    <>
      <Styles.Container>
        <Styles.TopLeftShape />
        <Styles.MiddleLeftShape />
        <Styles.LeftShapeBottom />
        <Styles.TopRightShape />
        <Styles.MiddleRightShape />
        <Styles.BottomRightShape />

        <Grid container spacing={1} justify="center">
          <Grid item xs={10} sm={8} md={8} lg={12}>
            <Styles.Content>
              {forgotPassword ? (
                <ForgotPasswordContent
                  toggleForgotPassword={toggleForgotPassword}
                />
              ) : (
                <LoginContent toggleForgotPassword={toggleForgotPassword} />
              )}
              <Styles.VersionContainer>
                <Title defaultFontSize>{versionAndEnvironment}</Title>
              </Styles.VersionContainer>
            </Styles.Content>
          </Grid>
        </Grid>
      </Styles.Container>
    </>
  );
}

export default React.memo(Login);
