import { paths } from "../navigation/navigate";
import { Home, AccountBalanceWallet, Group } from "@material-ui/icons/";

import Logos from "../config/logos";
import Icons from "../config/icons";
import texts from "./texts";

const Texts = texts["pt-BR"].pagesConfig;
const pagesConfig = {
  notLogged: [
    {
      navigationId: 0,
      path: paths.login,
      name: "Login",
    },
    {
      navigationId: 1,
      path: paths.forgotPassword,
      name: "ForgotPassword",
    },
  ],
  logged: [
    {
      groupTitle: "Principal",
      dontShow: false,
      pages: [
        {
          navigationId: 0,
          path: paths.home,
          icon: Home,
          title: "Home",
          working: true,
          dontShow: false,
        },
        {
          navigationId: 1,
          path: paths.cashBack,
          icon: AccountBalanceWallet,
          title: "Cashback",
          name: "Cashback",
          working: false,
          dontShow: false,
        },
        {
          navigationId: 2,
          path: paths.users,
          icon: Group,
          title: "Usuários",
          name: "UsersPage",
          working: false,
          dontShow: false,
        },
      ],
    },
    {
      groupTitle: "Verticais",
      icon: undefined,
      dontShow: false,
      pages: [
        {
          navigationId: 3,
          path: paths.beHomeRequestedReservations,

          subicon: Logos.behomeLogo,
          title: "Bewiki Home",

          color: "#00796D",
          name: "BeHomeRequestedReservations",
          working: true,
          dontShow: false,
          subPage: [
            {
              navigationId: 2,
              path: paths.beHomeRequestedReservations,
              name: "BeHomeRequestedReservations",
              title: "Reservas",
              working: true,
              showOnsubMenu: true,
            },
            // {
            //   navigationId: 5,
            //   path: paths.behomePendingCheckIns,
            //   title: Texts.beHome.PendingCheckIns,
            //   name: "BehomePendingCheckIns",
            //   working: true,
            //   showOnsubMenu: true,
            // },
            // {
            //   navigationId: 6,
            //   path: paths.beHomePrevisionFlow,
            //   title: Texts.beHome.BehomePrevisonFlow,
            //   name: "BehomePrevisionFlow",
            //   working: true,
            //   showOnsubMenu: true,
            //   NewRequest: false,
            // },
            // {
            //   navigationId: 4,
            //   path: paths.behomeReservationImport,
            //   title: Texts.beHome.ReservationImport,
            //   name: "BehomeReservationImport",
            //   working: true,
            //   showOnsubMenu: true,
            // },

            // {
            //   navigationId: 7,
            //   path: paths.behomeStudiosList,
            //   title: Texts.beHome.BehomeStudiosList,
            //   name: "BehomeStudiosList",
            //   working: true,
            //   showOnsubMenu: true,
            //   NewRequest: false,
            // },

            // {
            //   navigationId: 0,
            //   path: paths.beHomeRegister,
            //   title: Texts.beHome.RegisterApartment,
            //   name: "BehomeRegister",
            //   working: true,
            //   showOnsubMenu: true,
            // },
            // {
            //   navigationId: 1,
            //   path: paths.behomeStudiosEditing,
            //   title: Texts.beHome.BehomeStudiosEditing,
            //   name: "BehomeStudioEditing",
            //   working: true,
            //   showOnsubMenu: true,
            // },
            // {
            //   navigationId: 2,
            //   path: paths.behomeHistoricList,
            //   title: Texts.beHome.Historic,
            //   name: "BehomeHistoric",
            //   working: true,
            //   showOnsubMenu: true,
            // },
            // {
            //   navigationId: 8,
            //   path: paths.behomeSignatures,
            //   title: Texts.beHome.BehomeSignatures,
            //   name: "BehomeSignatures",
            //   working: true,
            //   showOnsubMenu: true,
            // },
          ],
        },

        {
          navigationId: 1,
          path: paths.beWorkHistoricRelease,

          subicon: Logos.beworkLogo,
          title: "Bewiki Care",

          color: "#4988C7",
          name: "BeWorkHistoricRelease",
          working: false,
          dontShow: false,
          subPage: [
            {
              navigationId: 1,
              path: paths.beWorkHistoricRelease,
              title: "Histórico de Lançamentos",
              name: "BeWorkHistoricRelease",
              working: true,
              showOnsubMenu: true,
            },
          ],
        },
        {
          navigationId: 0,
          path: undefined,

          subicon: Logos.becareLogo,
          title: "Bewiki Work",

          color: "#2C479E",

          name: "Becare",
          working: false,
          dontShow: false,
        },
        {
          navigationId: 2,
          path: undefined,

          subicon: Logos.bemobiLogo,
          title: "Bewiki Mobi",

          color: "#8F5EA7",
          name: "Bemobi",
          working: false,
          dontShow: false,
        },

        {
          navigationId: 4,
          path: paths.beMarketCouponList,

          subicon: Logos.bemarketLogo,
          title: "Bewiki Market",

          color: "#F57F26",
          name: "Bemarket",
          working: false,
          dontShow: false,
          subPage: [
            {
              navigationId: 0,
              path: paths.beMarketRestaurantLists,
              title: Texts.beMarket.RestaurantLists,
              working: true,
              showOnsubMenu: true,
            },
            {
              navigationId: 1,
              path: paths.beMarketCouponList,
              title: Texts.beMarket.CouponList,
              working: true,
              showOnsubMenu: true,
            },
          ],
        },
        {
          navigationId: 5,
          path: undefined,

          subicon: Logos.beparkLogo,
          title: "Bewiki Park",

          color: "#FAA61A",
          name: "Bepark",
          working: false,
          dontShow: false,
        },
      ],
    },
    {
      dontShow: true,
      pages: [
        {
          navigationId: 0,
          path: paths.home,
          name: "Home",
          working: true,
        },
        {
          navigationId: 1,
          path: paths.changePassword,
          name: "ChangePassword",
        },
        /*{
          navigationId: 2,
          path: paths.userRegister,
          name: "UserRegister",
        },*/
        {
          navigationId: 2,
          path: paths.cashBack,
          name: "CashBackPage",
        },
        {
          navigationId: 2,
          path: paths.users,
          name: "UsersPage",
        },
        {
          navigationId: 2,
          path: paths.beWorkHistoricRelease,
          name: "BeWorkHistoricRelease",
        },
        {
          navigationId: 3,
          path: paths.beHomeRequestedReservations,
          name: "BeHomeRequestedReservations",
        },
        {
          navigationId: 3,
          path: paths.behomeReservationImport,
          name: "BehomeReservationImport",
        },
        {
          navigationId: 3,
          path: paths.beHomeRegister,
          name: "BehomeRegister",
        },
        {
          navigationId: 3,
          path: paths.behomeStudiosEditing,
          name: "BehomeStudioEditing",
        },
        {
          navigationId: 3,
          path: paths.behomeHistoricList,
          name: "BehomeHistoric",
        },
        {
          navigationId: 3,
          path: paths.beHomePrevisionFlow,
          name: "BehomePrevisionFlow",
        },
        {
          navigationId: 3,
          path: paths.behomeStudiosList,
          name: "BehomeStudiosList",
        },
        {
          navigationId: 3,
          path: paths.behomePendingCheckIns,
          name: "BehomePendingCheckIns",
        },
        {
          navigationId: 3,
          path: paths.behomeSignatures,
          name: "BehomeSignatures",
        },
        {
          navigationId: 3,
          path: paths.behomeReservationImport,
          name: "BehomeReservationImport",
        },
        {
          navigationId: 3,
          path: paths.beMarketCouponList,
          name: "BeMarketCouponList",
        },
        {
          navigationId: 3,
          path: paths.beMarketRestaurantLists,
          name: "BeMarketRestaurantLists",
        },
      ],
    },
  ],
};
export default pagesConfig;
