import React from "react";
import Styles from "../styles/Styles";
import { connect } from "react-redux";
import { PagesConfig, Spacing } from "../../../config";
import { navigate } from "../../../navigation/navigate";
import { Grid, Hidden } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Badge } from "@material-ui/core";
import { CustomText } from "../../../components";
import fonts from "../../../config/fonts";

function PageGuide({
  pages,
  pathname,
  IconVertical,
  name,
  children,
  vertical,
}) {
  const classes = Styles.useStyles();
  const newRequest = null;
  const [showNavigation, setShowNavigation] = React.useState(false);
  const goto = (item) => {
    navigate(item.path);
  };

  const getVerticalColor = (vertName) => {
    if (vertName === "BeHomeRequestedReservations") {
      return "#00796D";
    } else {
      return null;
    }
  };
  const drawNavButtons = pages ? (
    <>
      {pages.map((item, index) => {
        if (item.showOnsubMenu === true) {
          const Button =
            item.path === pathname
              ? Styles.ButtonContained
              : Styles.ButtonOutlined;
          return (
            <Styles.ButtonContainer key={index}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button
                  pageColor={getVerticalColor(vertical)}
                  onClick={() => goto(item)}
                  style={{ height: "45px" }}
                >
                  {item.title}
                  {newRequest ? (
                    <Styles.NotificationContainer>
                      <Badge color="secondary" variant="dot">
                        <Styles.NotificationIcon />
                      </Badge>
                      <Styles.NotificationValue>
                        + {newRequest}
                      </Styles.NotificationValue>
                    </Styles.NotificationContainer>
                  ) : null}
                  <Hidden xsDown implementation="css">
                    <ChevronRightIcon />
                  </Hidden>
                </Button>
              </Grid>
            </Styles.ButtonContainer>
          );
        } else {
          return null;
        }
      })}
    </>
  ) : null;

  return (
    <Styles.Container>
      <Grid container spacing={1} direction="row" justify="flex-start">
        {pages?.length > 0 && (
          <Grid item xs={12} sm={12} md={2} lg={2}>
            {pages ? (
              <Hidden smUp implementation="css">
                <Styles.CardNavigation $defaultPadding $defaultMargin>
                  <Styles.MenuTitleButton
                    aria-label="menu"
                    onClick={() => setShowNavigation((open) => !open)}
                  >
                    Funções {name}
                    <Styles.IconExpandMore />
                  </Styles.MenuTitleButton>
                  {showNavigation ? <>{drawNavButtons}</> : null}
                </Styles.CardNavigation>
              </Hidden>
            ) : null}

            {pages ? (
              <Hidden xsDown implementation="css">
                <Styles.CardNavigation>
                  <CustomText
                    fontFamily={fonts.bold}
                    fontSize={2}
                    color="black"
                    style={{
                      paddingInline: Spacing(2.5),
                      paddingBottom: Spacing(2.5),
                    }}
                  >
                    Funções {name}
                  </CustomText>

                  {drawNavButtons}
                </Styles.CardNavigation>
              </Hidden>
            ) : null}
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={pages?.length > 0 ? 10 : 12}
          lg={pages?.length > 0 ? 10 : 12}
        >
          {children}
        </Grid>
      </Grid>
    </Styles.Container>
  );
}

function getNavigationPagesAndCheckFilter(item, pathname) {
  let j = 0;
  const jMax = item.pages.length;
  for (; j < jMax; j++) {
    const page = item.pages[j];
    let result = null;
    if (page.subPage) {
      for (let index = 0; index < page.subPage.length; index++) {
        if (pathname === page.subPage[index].path) {
          result = {
            pages: page.subPage,
            IconVertical: page.subicon,
            name: page.title,
          };
          return result;
        }
      }
    }
  }
  return {
    pages: null,
    IconVertical: null,
    name: null,
  };
}

function getNavigationGroup(pathname) {
  const items = PagesConfig.logged;
  let i = 0;
  const iMax = items.length;
  let result = null;
  for (; i < iMax; i++) {
    const item = items[i];
    if (item.groupTitle && item.pages) {
      result = getNavigationPagesAndCheckFilter(item, pathname);
    }
    if (result?.pages) {
      break;
    }
  }
  return result;
}
function mapStateToProps({ router }) {
  const { pages, IconVertical, name } = getNavigationGroup(
    router.location.pathname
  );
  return {
    pages,
    IconVertical,
    pathname: router.location.pathname,
    name,
  };
}

export default connect(mapStateToProps)(React.memo(PageGuide));
