export default function buildQueryParamsUrl(endpoint, params) {
  const query = Object.keys(params)
    .filter((key) => params[key] != null) // Filtra os valores que não são null ou undefined
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");

  return `${endpoint}?${query}`;
}
