import React from "react";
import { Homepage } from "../features";

function UserRegister() {
  return (
    <>
      <p style={{ color: "black" }}>Teste 2</p>
      <Homepage />
    </>
  );
}

export default UserRegister;
