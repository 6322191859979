import {
  isAfter as isAfterFns,
  isBefore as isBeforeFns,
  set as setFns,
  format as fnsFormat,
} from "date-fns";
import filters from "./Filters";

function compareDates(selectedDate, testDate, isAfter, withDay) {
  const testFunction = isAfter ? isAfterFns : isBeforeFns;
  const secDate = setFns(selectedDate, {
    hours: 0,
    minutes: 0,
    milliseconds: 0,
    seconds: 0,
  });
  const tstDate = setFns(testDate, {
    hours: 0,
    minutes: 0,
    milliseconds: 0,
    seconds: 0,
  });

  if (testFunction(secDate, tstDate)) {
    return {
      isValid: false,
      errorMessage: `A data não pode ser ${
        isAfter ? "posterior" : "inferior"
      } a ${
        withDay
          ? fnsFormat(testDate, "dd/MM/yyyy")
          : fnsFormat(testDate, "MM/yyyy")
      }`,
    };
  } else {
    return { isValid: true };
  }
}

const isDATE = (date, minDate, maxDate) => {
  if (!date) {
    return { isValid: false, errorMessage: "Insira uma data" };
  } else if (isNaN(date.getTime())) {
    return { isValid: false, errorMessage: "Insira uma data válida" };
  }

  if (minDate) {
    const { isValid, errorMessage } = compareDates(date, minDate, false, true);
    if (!isValid) {
      return { isValid, errorMessage };
    }
  }

  if (maxDate) {
    const { isValid, errorMessage } = compareDates(date, maxDate, true, true);
    if (!isValid) {
      return { isValid, errorMessage };
    }
  }
  return { isValid: true, errorMessage: " " };
};

const isEMAIL = (email) => {
  let errorMessage = "E-mail inválido";
  if (!email) {
    return { isValid: false, errorMessage: "Insira um email" };
  }
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return { isValid: true, errorMessage };
  }
  return { isValid: false, errorMessage };
};

function hasSpecialCharacters(password) {
  return /\W|_/g.test(password) ? 1 : 0;
}

function hasUppercase(password) {
  return /[A-ZÇ]/g.test(password) ? 1 : 0;
}

function hasNumber(password) {
  return /\d/g.test(password) ? 1 : 0;
}

function hasLowercase(password) {
  return /[a-zç]/g.test(password) ? 1 : 0;
}

function validPassword(password) {
  if (password.length < 8) {
    return {
      isValid: false,
      errorMessage: "Senha deve ter no Mínimo 8 caracteres",
    };
  }

  const { length } = password;
  for (let i = 0; i < length - 3; i++) {
    const testedChar = password.charCodeAt(i);
    if (
      testedChar === password.charCodeAt(i + 1) - 1 &&
      testedChar === password.charCodeAt(i + 2) - 2 &&
      testedChar === password.charCodeAt(i + 3) - 3
    ) {
      return {
        isValid: false,
        errorMessage:
          "Senha não pode conter sequências de 4 caracteres ou mais. ex: 1234/abcd",
      };
    } else if (
      testedChar === password.charCodeAt(i + 1) + 1 &&
      testedChar === password.charCodeAt(i + 2) + 2 &&
      testedChar === password.charCodeAt(i + 3) + 3
    ) {
      return {
        isValid: false,
        errorMessage:
          "Senha não pode conter sequências de 4 caracteres ou mais. ex: 4321/dcba",
      };
    }
  }

  if (
    hasSpecialCharacters(password) +
      hasLowercase(password) +
      hasNumber(password) +
      hasUppercase(password) <
    3
  ) {
    return {
      isValid: false,
      errorMessage:
        "Senha inválida. A senha deve conter pelo menos 3 das regras a seguir: Letras maiúsculas, minúsculas, números e caracteres especiais",
    };
  }

  return { isValid: true, errorMessage: "Senha válida" };
}

function checkDDIPhone(cellphone) {
  let errorMessage = "O campo deve ser preenchido com DDI, DDD e número.";
  if (!cellphone) {
    return { isValid: false, errorMessage: errorMessage };
  }

  const number = filters.removeNumberMask(cellphone);

  const isLengthValid =
    number.length === 10 ||
    number.length === 11 || // Sem DDI
    (number.length >= 12 && number.length <= 14); // Com DDI

  if (!isLengthValid) {
    return {
      isValid: false,
      errorMessage:
        "O número deve conter um DDD (2 dígitos) e um número de telefone (8 a 9 dígitos) com ou sem DDI (1 a 3 dígitos).",
    };
  }

  // Definir índice de início do DDD dependendo da presença do DDI
  let dddIndex =
    number.length === 10 || number.length === 11 ? 0 : number.length - 10;
  const ddd = Number(number.substring(dddIndex, dddIndex + 2));
  const isDDDValid = ddd >= 11 && ddd <= 99;

  if (!isDDDValid) {
    errorMessage = "Deve ser informado um DDD válido (entre 11 e 99).";
  }

  // Se o número tem mais de 11 dígitos, então espera-se um DDI
  if (number.length > 11) {
    const ddi = Number(number.substring(0, dddIndex));
    const isDDIValid = ddi > 0;
    if (!isDDIValid) {
      errorMessage = "Deve ser informado um DDI válido.";
      return errorMessage;
    }
  }

  return isLengthValid && isDDDValid
    ? { isValid: true, errorMessage: "" }
    : { isValid: false, errorMessage: errorMessage };
}
const inputDate = (data, maxDate, minDate) => {
  const zerarTempo = (data) => {
    const novaData = new Date(data);
    novaData.setHours(0, 0, 0, 0);
    return novaData;
  };

  if (isNaN(Date.parse(data))) {
    return { isValid: false, errorMessage: "Insira uma Data Valida" };
  } else {
    if (zerarTempo(data) > zerarTempo(maxDate)) {
      return {
        isValid: false,
        errorMessage: `Insira uma Data anterior a ${new Date(
          maxDate
        ).toLocaleDateString("pt-BR")}`,
      };
    } else if (zerarTempo(data) < zerarTempo(minDate)) {
      return {
        isValid: false,
        errorMessage: `Insira uma Data posterior a ${new Date(
          minDate
        ).toLocaleDateString("pt-BR")}`,
      };
    }
    return {
      isValid: true,
      errorMessage: "",
    };
  }
};
function checkPhone(cellphone) {
  let errorMessage =
    "O campo deve ser preenchido com DDD e número ou DDI, DDD e número.";
  if (!cellphone) {
    return { isValid: false, errorMessage };
  }

  const number = filters.removeNumberMask(cellphone);

  // Validar comprimento de número com DDI (13 ou 14 dígitos) ou sem DDI (10 ou 11 dígitos)
  const isCellphoneValid =
    number.length === 10 ||
    number.length === 11 ||
    number.length === 13 ||
    number.length === 14;

  if (!isCellphoneValid) {
    return { isValid: false, errorMessage };
  }

  // Extraímos o DDD baseado na presença ou ausência do DDI
  let dddIndex =
    number.length === 10 || number.length === 11 ? 0 : number.length - 11;
  const ddd = Number(number.substring(dddIndex, dddIndex + 2));
  const isDDDValid = ddd >= 11 && ddd <= 99;

  if (!isDDDValid) {
    errorMessage = "Deve ser informado um DDD válido (entre 11 e 99).";
    return { isValid: false, errorMessage };
  }

  // Se tiver DDI, verificar se o DDI é válido
  if (number.length === 13 || number.length === 14) {
    const ddi = Number(number.substring(0, dddIndex));
    const isDDIValid = ddi > 0; // Aqui você pode definir regras mais específicas para DDI, se necessário
    if (!isDDIValid) {
      errorMessage = "Deve ser informado um DDI válido.";
      return { isValid: false, errorMessage };
    }
  }

  return { isValid: true, errorMessage }; // Se passar todas as verificações
}
const validations = {
  isEMAIL,
  validPassword,
  isDATE,
  checkDDIPhone,
  inputDate,
  checkPhone,
};

export default validations;
