import { Filters } from "../../lib";
export default function getBeHomeRequestedReservations(response) {
  if (response.status === 204) {
    return { data: { content: [], totalPages: 0 } };
  }
  let data = response.data.content.map((item, i) => {
    return {
      ...item,
      createdAt: item?.createdAt,
      id: item?.id,
      name: item?.name,

      checkIn: item?.checkIn,
      checkOut: item?.checkOut,
      reservationCode: item?.reservationCode,
      confirmed: item?.confirmed,
    };
  });
  return {
    ...response,
    data: { content: data, totalPages: response.data.totalPages },
  };
}
