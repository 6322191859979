import React, { useState } from "react";
import Styles from "../styles/Styles";

import { Grid } from "@material-ui/core";

import {
  ButtonText,
  CustomText,
  Input,
  InputDate,
  Select,
} from "../../../components";
import fonts from "../../../config/fonts";
import { FormHolder } from "../../../FormConfig";
import filters from "../../../lib/Filters";
import { Fonts } from "../../../config";

function TypeData({ typeData, setTypeData, loading }) {
  const topBar = [
    {
      label: "Bewiki Home",
      value: 0,
      disabled: false,
    },
    {
      label: "Custos",
      value: 1,
      disabled: true,
    },
    {
      label: "Bewiki Care/Hd",
      value: 2,
      disabled: true,
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        {topBar?.map((item) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              borderBottom:
                item.value === typeData ? "5px solid black" : "5px solid gray",
            }}
          >
            <Styles.ButtonType
              $on={item.value !== typeData}
              disabled={item?.disabled}
              onClick={() => {
                setTypeData(item.value);
              }}
            >
              <CustomText fontSize={1.75} fontFamily={Fonts.semibold}>
                {item?.label}
              </CustomText>
            </Styles.ButtonType>
          </div>
        ))}
      </div>
    </>
  );
}

export default TypeData;
