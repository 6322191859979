import { ApproveCheckInModalStyles as Styles } from "../../styles";
import React from "react";
import { Grid } from "@material-ui/core";
import { Texts } from "../../../../../config";
import { Input } from "../../../../../components";
import { connect } from "react-redux";
import { Creators } from "../../reduxSagas";
import { ContainerInputs } from "../../../../../components/inputs/inputsComponents";
import displayDatePeriodText from "../../../../../services/helpers/displayDatePeriodText";
import { FormHolder } from "../../../../../FormConfig";
import { customModal } from "../../../../modals/utils";

function ApproveCheckInModalInside({
  userName,
  pendingCheckInData,
  ApproveCheckInRequest,
  isFetching,
  reloadTable,
}) {
  const texts = Texts["pt-BR"].beHome.PendingCheckIns.modalApproveCheckIn;
  const { StayTypes } = Texts["pt-BR"].beHome;
  const handleCloseModal = () => {
    customModal.close();
  };
  const handleApproveCheckIn = () => {
    ApproveCheckInRequest(pendingCheckInData?.checkIn?.id, reloadTable);
  };

  return (
    <FormHolder>
      <Styles.HeaderContainer $defaultMargin>
        <Styles.Title>{texts.userDataTitle}</Styles.Title>
      </Styles.HeaderContainer>
      <Styles.ModalContent>
        <Grid container spacing={1} direction="row">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ContainerInputs
              inputLabel={
                <Styles.InputTitle>{texts.userName}</Styles.InputTitle>
              }
              children={
                <Input
                  small
                  marginDefault
                  defaultBorder
                  name="user"
                  defaultValue={userName}
                  readOnly
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <ContainerInputs
              inputLabel={<Styles.InputTitle>{texts.stay}</Styles.InputTitle>}
              children={
                <Input
                  name="stay"
                  small
                  marginDefault
                  defaultBorder
                  defaultValue={displayDatePeriodText(
                    pendingCheckInData.stay.dateCheckIn,
                    pendingCheckInData.stay.dateCheckOut
                  )}
                  readOnly
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <ContainerInputs
              inputLabel={
                <Styles.InputTitle>{texts.stayType}</Styles.InputTitle>
              }
              children={
                <Input
                  name="type"
                  small
                  marginDefault
                  defaultBorder
                  defaultValue={StayTypes[pendingCheckInData.stay.type]}
                  readOnly
                />
              }
            />
          </Grid>
        </Grid>
      </Styles.ModalContent>
      <Styles.FooterModal>
        <Styles.TextFieldButton
          disabled={isFetching}
          loading={isFetching}
          fullWidth={false}
          onClick={handleCloseModal}
        >
          {texts.cancel}
        </Styles.TextFieldButton>

        <Styles.TextFieldButton
          fullWidth={false}
          $defaultColor
          onClick={handleApproveCheckIn}
          disabled={isFetching}
          loading={isFetching}
        >
          {texts.confirm}
        </Styles.TextFieldButton>
      </Styles.FooterModal>
    </FormHolder>
  );
}
function mapStateToProps(state) {
  const { isFetching } = state.beHomePendingCheckIns;
  return { isFetching };
}

function mapDispatchToProps(dispatch) {
  const { ApproveCheckInRequest } = Creators;
  return {
    ApproveCheckInRequest: function (checkInId, setCheckInCancelled) {
      return dispatch(ApproveCheckInRequest(checkInId, setCheckInCancelled));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApproveCheckInModalInside);
