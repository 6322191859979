import React, { useEffect, useRef } from "react";

import { Chart } from "chart.js";
import CustomText from "../others/CustomText";
import fonts from "../../config/fonts";
import { Filters } from "../../lib";

function ChartBox({
  isFetching,
  chartData,
  height,
  width,
  name,
  withAverage,
  type,
  extraData,
}) {
  const chartRef = useRef(null);
  const [, setChart] = React.useState();
  const canvasCallback = (canvas) => {
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      chartRef.current?.destroy();
      chartRef.current = new Chart(ctx, chartData);
    }
  };
  React.useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = chartData.data;
      chartRef.current.update();
    }
  }, [isFetching, chartData, setChart]);
  console.log(chartData);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          flexWrap: "wrap",
        }}
      >
        {withAverage && chartData && chartData?.options?.lineAverage && (
          <div
            style={{
              backgroundColor: "#00796D",
              paddingBlock: "6px",
              paddingInline: "15px",
              width: "fit-content",
              borderRadius: "4px",
              marginTop: "6px",
            }}
          >
            <CustomText
              fontSize={1.5}
              fontFamily={fonts.semibold}
              textColor="white"
            >
              Media:{" "}
              {Filters.convertNumberInputMask(
                chartData?.options?.lineAverage?.toFixed(2),
                type === "money" ? true : false,
                true
              )}
            </CustomText>
          </div>
        )}

        {extraData &&
          chartData &&
          chartData?.options?.lastTwo?.map((item) => (
            <div
              style={{
                backgroundColor: "#00796D",
                paddingBlock: "6px",
                paddingInline: "15px",
                width: "fit-content",
                borderRadius: "4px",
                marginTop: "6px",
              }}
            >
              <CustomText
                fontSize={1.5}
                fontFamily={fonts.semibold}
                textColor="white"
              >
                {item?.label}:{" "}
                {Filters.convertNumberInputMask(
                  item.value?.toFixed(2),
                  type === "money" ? true : false,
                  true
                )}
              </CustomText>
            </div>
          ))}
      </div>

      <canvas
        id="teste"
        style={{
          height: height ? height : "250px",
          maxWidth: width ? width : "100%",
          maxHeight: height ? height : "250px",
        }}
        ref={canvasCallback}
      ></canvas>
    </div>
  );
}

export default ChartBox;
