import createReducers from "../../../../store/helpers/createPageReducer";

import ReservationsRequest from "./ReservationsRequest";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "reservationsRequest",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: true,
        requestedReservationsData: null,
        lastFilter: data,
      }),
      sagaFunction: ReservationsRequest,
    },

    {
      name: "ReservationsRequestSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        requestedReservationsData: data,
      }),
    },

    {
      name: "ReservationsRequestFailure",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        requestedReservationsData: null,
      }),
    },
  ],
  {
    isFetching: false,
    requestedReservationsData: null,
    lastFilter: null,
  }
);

export { Creators, reducers, sagas };
