import Chart from "chart.js";
import { FontStyles } from "../../components";
Chart.defaults.global.defaultFontFamily = "neue roman";

export function createBarScalesX(
  gridColor,
  labelsColor,
  stacked,
  max,
  maxTick = 7.5,
  type = "money"
) {
  return {
    defaultFontFamily: "Montserrat bold",
    yAxes: [
      {
        stacked: false,
        gridLines: {
          drawTicks: false,

          color: gridColor,
        },
        ticks: {
          fontFamily: FontStyles.medium12.fontFamily,
          fontSize: 12,
          fontColor: labelsColor,
          beginAtZero: true,
          maxTicksLimit: maxTick,
          padding: 20,
          max,
          callback: function (value, index, values) {
            let valueDecimal = 0;
            if (value >= 1_000_000_000) {
              valueDecimal = (value / 1_000_000_000).toFixed(2) + " Bi";
            } else if (value >= 1_000_000) {
              valueDecimal = (value / 1_000_000).toFixed(2) + " M";
            } else if (value >= 1_000) {
              valueDecimal = (value / 1_000).toFixed(2) + " mil";
            } else {
              valueDecimal = value.toFixed(2);
            }

            if (type === "money") {
              return "R$ " + valueDecimal;
            } else if (type === "percent") {
              return valueDecimal + "%";
            } else {
              return value;
            }
          },
        },
      },
    ],
    xAxes: [
      {
        stacked: false,
        gridLines: {
          borderDash: [10, 10],

          color: gridColor,
        },
        ticks: {
          fontFamily: FontStyles.medium12.fontFamily,
          fontSize: 12,
          fontColor: labelsColor,
          beginAtZero: false,
          padding: 20,
          maxTicksLimit: 100000,
        },
      },
    ],
  };
}

export function createBarScalesXMobile(
  gridColor,
  labelsColor,
  stacked,
  max,
  maxTick = 7.5,
  type = "money"
) {
  return {
    defaultFontFamily: "Montserrat bold",
    yAxes: [
      {
        stacked: false,
        gridLines: {
          drawTicks: false,

          color: gridColor,
        },
        ticks: {
          fontFamily: FontStyles.medium12.fontFamily,
          fontSize: 10,
          fontColor: labelsColor,
          beginAtZero: true,
          maxTicksLimit: maxTick,
          padding: 8,
          max,
          callback: function (value, index, values) {
            let valueDecimal = 0;
            if (value >= 1_000_000_000) {
              valueDecimal = (value / 1_000_000_000).toFixed(0) + "B";
            } else if (value >= 1_000_000) {
              valueDecimal = (value / 1_000_000).toFixed(0) + "M";
            } else if (value >= 1_000) {
              valueDecimal = (value / 1_000).toFixed(0) + "K";
            } else if (value <= 0) {
              valueDecimal = value.toFixed(0);
            } else {
              valueDecimal = value.toFixed(2);
            }

            if (type === "money") {
              return "R$ " + valueDecimal;
            } else if (type === "percent") {
              return valueDecimal + "%";
            } else {
              return value;
            }
          },
        },
      },
    ],
    xAxes: [
      {
        stacked: false,
        gridLines: {
          borderDash: [10, 10],

          color: gridColor,
        },
        ticks: {
          fontFamily: FontStyles.medium12.fontFamily,
          fontSize: 10,
          fontColor: labelsColor,
          beginAtZero: false,
          padding: 8,
          maxTicksLimit: 100000,
        },
      },
    ],
  };
}

export function createLayoutWithDatalabels() {
  return {
    mode: "dataset",
    padding: {
      top: 40,
      right: 60,
      bottom: 0,
      left: 16,
    },
  };
}

export function createLayoutWithDatalabelsMobile() {
  return {
    mode: "dataset",
    padding: {
      top: 30,
      right: 10,
      bottom: 0,
      left: 10,
    },
  };
}
