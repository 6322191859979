import React from "react";
import { Typography } from "@material-ui/core";
import { Fonts } from "../../config";
import styled from "styled-components";

const StyledText = styled(Typography)(({ theme, $defaultFontSize }) => {
  const colors = theme.palette;
  return {
    fontFamily: Fonts.bold,
    fontSize: $defaultFontSize ? 14 : Fonts.sizes.medium,
    color: colors.text.primary,
  };
});

function Title({
  children,
  loading,
  style,
  loadingSizes = [],
  defaultFontSize,
  ...props
}) {
  const [width = 2, height = 1] = loadingSizes;
  return loading ? (
    <div height={height} width={width}></div>
  ) : (
    <StyledText $defaultFontSize={defaultFontSize} {...props}>
      {children}
    </StyledText>
  );
}

export default React.memo(Title);
