import React from "react";
import { ModalStyles as Styles } from "../styles";
import { Grid } from "@material-ui/core";
import { Texts } from "../../../../config";
import { FormHolder } from "../../../../FormConfig";
import { Input, Select } from "../../../../components";
import { ContainerInputs } from "../../../../components/inputs/inputsComponents";
//import { Creators } from "../reduxSagas";
//import { connect } from "react-redux";
import { customModal } from "../../../modals/utils";
import { Creators } from "../reduxSagas";
import { connect } from "react-redux";

function TransferModal({
  tableData,
  isFetching,
  BehomeHistoricTransfer,
  BeHomeHistoricCategoryRequest,
  StayCategorytData,
  BehomeHistoricStayRequest,
  StayRoomData,
}) {
  const text = Texts["pt-BR"].beHome.HistoricList.ModalTransfer;

  React.useEffect(() => {
    if (tableData) {
      const stayTypeMap = {
        S: "SHORT_STAY",
        L: "LONG_STAY",
      };
      BeHomeHistoricCategoryRequest(stayTypeMap[tableData.stayType]);
    }
  }, [BeHomeHistoricCategoryRequest, tableData]);

  const handleChange = (event, selectedCategoryId) => {
    if (selectedCategoryId !== null && selectedCategoryId !== undefined) {
      BehomeHistoricStayRequest(selectedCategoryId);
    }
  };

  const handleCloseModal = () => {
    customModal.close();
  };

  function sendParameters(data) {
    BehomeHistoricTransfer(tableData.stayType, tableData.id, data.idBedroom);
    handleCloseModal();
  }

  return (
    <>
      <FormHolder onSubmit={sendParameters}>
        <Styles.HeaderContainer $defaultMargin>
          <Styles.Title>{text.currentData}</Styles.Title>
        </Styles.HeaderContainer>

        <Styles.ModalContent>
          <Grid container spacing={1} direction="row">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ContainerInputs
                inputLabel={
                  <Styles.InputTitle>{text.inputUser}</Styles.InputTitle>
                }
                children={
                  <Input
                    name="user"
                    defaultValue={tableData.guest.name}
                    small
                    marginDefault
                    defaultBorder
                    readOnly
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ContainerInputs
                inputLabel={
                  <Styles.InputTitle>{text.inputStay}</Styles.InputTitle>
                }
                children={
                  <Input
                    name="stay"
                    defaultValue={tableData.stay.behomeStay}
                    small
                    marginDefault
                    defaultBorder
                    readOnly
                  />
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ContainerInputs
                inputLabel={
                  <Styles.InputTitle>{text.inputModality}</Styles.InputTitle>
                }
                children={
                  <Input
                    name="type"
                    small
                    marginDefault
                    defaultBorder
                    defaultValue={
                      tableData.stayType === "L" ? "Moradia" : "Hospedagem"
                    }
                    readOnly
                  />
                }
              />
            </Grid>
          </Grid>
        </Styles.ModalContent>

        <Styles.HeaderContainer>
          <Styles.Title $defaultColor>{text.transferData}</Styles.Title>
        </Styles.HeaderContainer>
        <Styles.SubTitle $defaultFonts $defaultMargin>
          {text.text2}
        </Styles.SubTitle>

        <Styles.ModalContent>
          <Grid container spacing={1} direction="row">
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ContainerInputs
                inputLabel={
                  <Styles.InputTitle>{text.selectCategory}</Styles.InputTitle>
                }
                children={
                  <Select
                    small
                    name="category"
                    disableError
                    onChange={handleChange}
                    options={
                      StayCategorytData
                        ? StayCategorytData?.map((item) => {
                            return {
                              value: item.id,
                              label: item.name,
                            };
                          })
                        : []
                    }
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ContainerInputs
                inputLabel={
                  <Styles.InputTitle>{text.idBedroom}</Styles.InputTitle>
                }
                children={
                  <Select
                    name="idBedroom"
                    small
                    disableError
                    options={
                      StayRoomData
                        ? StayRoomData?.map((item) => {
                            return {
                              value: item.id,
                              label: item.name,
                            };
                          })
                        : []
                    }
                  />
                }
              />
            </Grid>
          </Grid>
        </Styles.ModalContent>

        <Styles.FooterModal>
          <Grid container justify="flex-end">
            <Styles.TextButton $defaultColor onClick={() => handleCloseModal()}>
              {text.back}
            </Styles.TextButton>
            <Styles.TextButton
              type="submit"
              name="button"
              loading={isFetching}
              disabled={isFetching}
            >
              {text.confirm}
            </Styles.TextButton>
          </Grid>
        </Styles.FooterModal>
      </FormHolder>
    </>
  );
}

function mapStateToProps(state) {
  const { StayRoomData, StayCategorytData } = state.behomeHistoric;
  return {
    StayCategorytData,
    StayRoomData,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    BehomeHistoricTransfer,
    BehomeHistoricStayRequest,
    BeHomeHistoricCategoryRequest,
  } = Creators;
  return {
    BehomeHistoricTransfer: function (
      stayType,
      StaySubscriptionId,
      BehomeStayId
    ) {
      return dispatch(
        BehomeHistoricTransfer(stayType, StaySubscriptionId, BehomeStayId)
      );
    },
    BeHomeHistoricCategoryRequest: function (stayType) {
      return dispatch(BeHomeHistoricCategoryRequest(stayType));
    },
    BehomeHistoricStayRequest: function (behomeStayId) {
      return dispatch(BehomeHistoricStayRequest(behomeStayId));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(TransferModal);
