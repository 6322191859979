import React from "react";
import styled from "styled-components";
import FontStyles from "../styles/fontsStyles";
import SkeletonLoader from "../others/SkeletonLoader";
import Text from "../strings/Text";
import { Fonts } from "../../config";
import CustomText from "../others/CustomText";

const RowContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "4px",
  };
});

function ChartMultiDescription({ listDescription }) {
  return (
    <RowContainer style={{ gap: "14px" }}>
      {listDescription.map((item) => (
        <RowContainer>
          {item.type === "square" ? (
            <div
              style={{
                height: "12px",
                width: "12px",
                backgroundColor: "#00796D54",
                border: "2px solid #00796D",
              }}
            ></div>
          ) : (
            <div
              style={{
                height: "2px",
                width: "12px",
                backgroundColor: "#000000",
              }}
            ></div>
          )}

          <CustomText fontSize={1.5} fontFamily={Fonts.semibold}>
            {item.title}
          </CustomText>
        </RowContainer>
      ))}
    </RowContainer>
  );
}
export default ChartMultiDescription;
