import { CircularProgress, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { CustomText } from "../../../components";
import ChartBox from "../../../components/charts/chartBox";
import ChartMultiDescription from "../../../components/charts/chartMultiDescription";
import { Fonts, Spacing } from "../../../config";
import { Filters } from "../../../lib";
import filters from "../../../lib/Filters";
import { models } from "../../../utils";
import FiltersComponent from "../components/FilterComponent";
import TypeData from "../components/TypeData";
import { Creators } from "../reduxSagas";
import Styles from "../styles/Styles";

const chartData = null;
const chartData1 = null;
const chartData2 = null;
const onHold = true;
function HomePage({
  isFetching,
  dataDailyAverage,
  lastFilter,
  dashRequest,
  dataDailyAverageGraph,
  dashDailyAverageGraph,
  dataDailyAverageValue,
  dataDailyAverageValueGraph,
  dashDailyAverageValueGraph,
  dashDailyAverageValue,
}) {
  const [typeData, setTypeData] = useState(0);

  const onMount = React.useCallback(() => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 14);

    dashRequest({
      startDate: filters.dataToYYYYMMDD(startDate),
      endDate: filters.dataToYYYYMMDD(endDate),
    });
    dashDailyAverageGraph({
      startDate: filters.dataToYYYYMMDD(startDate),
      endDate: filters.dataToYYYYMMDD(endDate),
    });

    dashDailyAverageValueGraph({
      startDate: filters.dataToYYYYMMDD(startDate),
      endDate: filters.dataToYYYYMMDD(endDate),
    });
    dashDailyAverageValue({
      startDate: filters.dataToYYYYMMDD(startDate),
      endDate: filters.dataToYYYYMMDD(endDate),
    });
  }, []);

  React.useEffect(onMount, [onMount]);

  const filterDash = (data) => {
    dashRequest(data);

    dashDailyAverageGraph(data);
    dashDailyAverageValueGraph(data);
    dashDailyAverageValue(data);
  };

  const changeDash = (type) => {
    if (type === 0) {
      filterDash(null);
    }

    setTypeData(type);
  };

  const placeholderData = {
    numberOfDayGuest: chartData,
    averageOfDayTicket: chartData1,
    occupation: chartData2,
    averageReservations: 6,
    averageTicket: 2030.9,
  };

  return (
    <>
      <Grid item xs={12}>
        {/*   <TypeData
          loading={false}
          typeData={typeData}
          setTypeData={changeDash}
        /> */}
        <FiltersComponent
          loading={false}
          itensPerPage={2}
          onSubmit={(data) => filterDash(data)}
          lastFilter={lastFilter}
        />
      </Grid>
      <Grid item xs={12}>
        <Styles.Container style={{ marginBlock: "8px" }}>
          {isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: "32px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Styles.Background>
                  <CustomText
                    style={{ paddingBottom: "8px" }}
                    fontSize={2}
                    fontFamily={Fonts.bold}
                  >
                    Número médio de reservas no período
                  </CustomText>
                  {dataDailyAverage ? (
                    <CustomText fontSize={4} fontFamily={Fonts.extraBold}>
                      {dataDailyAverage
                        ? dataDailyAverage?.value
                            ?.toFixed(2)
                            ?.replace(".", ",") || 0
                        : "-"}
                    </CustomText>
                  ) : (
                    <Styles.EmptyContainer>
                      <CustomText
                        style={{ opacity: 0.8 }}
                        fontSize={3}
                        fontFamily={Fonts.bold}
                      >
                        Sem Dados
                      </CustomText>
                    </Styles.EmptyContainer>
                  )}
                </Styles.Background>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Styles.Background>
                  <CustomText
                    style={{ paddingBottom: "8px" }}
                    fontSize={2}
                    fontFamily={Fonts.bold}
                  >
                    Ticket médio de reserva no período
                  </CustomText>
                  {dataDailyAverageValue ? (
                    <CustomText fontSize={4} fontFamily={Fonts.extraBold}>
                      {dataDailyAverageValue
                        ? filters.convertMoneyTextMask(
                            dataDailyAverageValue?.value
                          ) || 0
                        : "-"}
                    </CustomText>
                  ) : (
                    <Styles.EmptyContainer>
                      <CustomText
                        style={{ opacity: 0.8 }}
                        fontSize={3}
                        fontFamily={Fonts.bold}
                      >
                        Sem Dados
                      </CustomText>
                    </Styles.EmptyContainer>
                  )}
                </Styles.Background>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Styles.Background>
                  <CustomText fontSize={2} fontFamily={Fonts.bold}>
                    Número de reservas por dia
                  </CustomText>
                  {dataDailyAverageGraph ? (
                    <>
                      <Styles.ContainerGraph>
                        <ChartBox
                          height={Spacing(45)}
                          name="teste"
                          chartData={models.getLineChart(dataDailyAverageGraph)}
                        />
                      </Styles.ContainerGraph>

                      <Styles.ContainerGraphMobile>
                        <ChartBox
                          withAverage
                          height={Spacing(35)}
                          name="teste"
                          chartData={models.getLineChartMobile(
                            dataDailyAverageGraph,
                            false
                          )}
                        />
                      </Styles.ContainerGraphMobile>
                      <ChartMultiDescription
                        listDescription={[
                          { type: "square", title: "Diárias do Período" },
                          { type: "line", title: "Média do Período" },
                        ]}
                      />
                    </>
                  ) : (
                    <Styles.EmptyContainer>
                      <CustomText
                        style={{ opacity: 0.8 }}
                        fontSize={3}
                        fontFamily={Fonts.bold}
                      >
                        Sem Dados
                      </CustomText>
                    </Styles.EmptyContainer>
                  )}
                </Styles.Background>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Styles.Background>
                  <CustomText fontSize={2} fontFamily={Fonts.bold}>
                    Valor total de reservas por dia
                  </CustomText>
                  {dataDailyAverageValueGraph ? (
                    <>
                      <Styles.ContainerGraph>
                        <ChartBox
                          height={Spacing(45)}
                          name="teste"
                          chartData={models.getLineChart({
                            ...dataDailyAverageValueGraph,
                            type: "money",
                          })}
                        />
                      </Styles.ContainerGraph>
                      <Styles.ContainerGraphMobile>
                        <ChartBox
                          extraData
                          withAverage
                          height={Spacing(35)}
                          name="teste"
                          type="money"
                          chartData={models.getLineChartMobile(
                            {
                              ...dataDailyAverageValueGraph,
                              type: "money",
                            },
                            true
                          )}
                        />
                      </Styles.ContainerGraphMobile>
                      <ChartMultiDescription
                        listDescription={[
                          { type: "square", title: "Diárias do Período" },
                          { type: "line", title: "Média do Período" },
                        ]}
                      />
                    </>
                  ) : (
                    <Styles.EmptyContainer>
                      <CustomText
                        style={{ opacity: 0.8 }}
                        fontSize={3}
                        fontFamily={Fonts.bold}
                      >
                        Sem Dados
                      </CustomText>
                    </Styles.EmptyContainer>
                  )}
                </Styles.Background>
              </Grid>
              {/* <Grid item xs={12} sm={12}>
              <Styles.Background>
                <CustomText fontSize={2} fontFamily={Fonts.bold}>
                  Taxa de Ocupação
                </CustomText>
                {onHold ? (
                  <Styles.EmptyContainer>
                    <CustomText
                      style={{ opacity: 0.8 }}
                      fontSize={3}
                      fontFamily={Fonts.bold}
                    >
                      Em Desenvolvimento
                    </CustomText>
                  </Styles.EmptyContainer>
                ) : (
                  <>
                    {" "}
                    <ChartBox
                      height={Spacing(37.5)}
                      name="teste2"
                      chartData={models.getLineChart(
                        placeholderData?.occupation
                      )}
                    />
                    <ChartMultiDescription
                      listDescription={[
                        {
                          type: "square",
                          title: "Taxa de Ocupação do Período",
                        },
                        { type: "line", title: "Média do Período" },
                      ]}
                    />
                  </>
                )}
              </Styles.Background>
            </Grid> */}
            </Grid>
          )}
        </Styles.Container>
      </Grid>
    </>
  );
}

function mapStateToProps(state) {
  const {
    dataDailyAverage,
    isFetching,
    lastFilter,
    dataDailyAverageGraph,
    dataDailyAverageValue,
    dataDailyAverageValueGraph,
  } = state.home;
  return {
    isFetching,
    dataDailyAverage,
    lastFilter,
    dataDailyAverageGraph,
    dataDailyAverageValue,
    dataDailyAverageValueGraph,
  };
}
function mapDispatchToProps(dispatch) {
  const {
    dashRequest,
    dashDailyAverageGraph,
    dashDailyAverageValue,
    dashDailyAverageValueGraph,
  } = Creators;
  return {
    dashRequest: function (data) {
      return dispatch(dashRequest(data));
    },
    dashDailyAverageGraph: function (data) {
      return dispatch(dashDailyAverageGraph(data));
    },

    dashDailyAverageValue: function (data) {
      return dispatch(dashDailyAverageValue(data));
    },
    dashDailyAverageValueGraph: function (data) {
      return dispatch(dashDailyAverageValueGraph(data));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
