import React from "react";
import { Table, Pagination } from "../../../../components";
import { Texts } from "../../../../config";
import { Filters } from "../../../../lib";

function CashBackTable({ data, page, setPage, loading }) {
  const texts = Texts["pt-BR"].cashBack;

  return (
    <>
      {data ? (
        <>
          <Table
            sortContent={() => {}}
            id="historicTable"
            headers={texts}
            data={data.content}
            placeholderSize={15}
            columnSize={6}
            withGroup
            justify={2}
            defaltAlign={2}
            firstDivision={4}
            secondDivision={4}
            loading={loading}
            renderItemColumns={(item) => [
              item.name + item.lastName === 0 ? "none" : null,
              Filters.fixDateToLabel(item.infoDate),
              Filters.convertMoneyTextMask(item.purchaseValue),
              Filters.convertMoneyTextMask(item.cashbackValue),
              <>&nbsp;</>,
              item.sector.verticalName,
            ]}
          />

          <Pagination
            page={page}
            setPage={setPage}
            totalPages={data.totalPages}
          />
        </>
      ) : null}
    </>
  );
}

export default CashBackTable;
