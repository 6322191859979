import { ReactComponent as becareIcon } from "../assets/icons/icon-becare.svg";
import { ReactComponent as beIcon } from "../assets/icons/icon-bepark.svg";
import { ReactComponent as behomeIcon } from "../assets/icons/icon-behome.svg";
import { ReactComponent as bemarketIcon } from "../assets/icons/icon-bemarket.svg";
import { ReactComponent as bemobiIcon } from "../assets/icons/icon-bemobi.svg";
import { ReactComponent as beworkIcon } from "../assets/icons/icon-bework.svg";
import { ReactComponent as rightShape } from "../assets/icons/RightShape.svg";
import { ReactComponent as leftShape } from "../assets/icons/LeftShape.svg";

import { ReactComponent as eyeDot } from "../assets/icons/icon-eye-dot.svg";
import { ReactComponent as keyPlus } from "../assets/icons/icon-key-plus.svg";
import { ReactComponent as behomeShape } from "../assets/icons/shape-03-green.svg";
import { ReactComponent as BeworkShape } from "../assets/icons/shape-04-blue.svg";
import { ReactComponent as BemobiShape } from "../assets/icons/shape-02-purple.svg";
import { ReactComponent as BecareShape } from "../assets/icons/shape-05-red.svg";
import { ReactComponent as BemarketShape } from "../assets/icons/shape-01-orange.svg";
import { ReactComponent as BeparkShape } from "../assets/icons/shape-06-gray.svg";

const Icons = {
  becareIcon,
  behomeIcon,
  bemarketIcon,
  bemobiIcon,
  beIcon,
  beworkIcon,
  rightShape,
  leftShape,
  eyeDot,
  keyPlus,
  behomeShape,
  BeworkShape,
  BemobiShape,
  BecareShape,
  BemarketShape,
  BeparkShape,
};

export default Icons;
