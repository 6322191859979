import React from "react";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { Spacing } from "../../config";
import dateFns from "@date-io/date-fns";
import { formConnector } from "../../FormConfig";
import styled from "styled-components";
import { Fonts } from "../../config";
import Text from "../strings/Text";
import { FormControl } from "@material-ui/core";
import FontStyle from "../styles/fontsStyles";

const InputDate = styled(KeyboardDatePicker)(
  ({ theme, noMargin, error, $gray }) => {
    const { spacing, palette: colors } = theme;
    return {
      ".MuiInputBase-root": {
        margin: spacing(0),

        marginTop: noMargin ? spacing(0) : spacing(2.7),
        padding: $gray
          ? spacing(0.75)
          : noMargin
          ? spacing(0.35)
          : spacing(0.5),
        paddingInline: 0,
        //border: 0,
        borderRadius: $gray ? 6 : 0,
        color: "black", //deixar cor como tema depois
        /*backgroundColor: $alternativeColors
          ? colors.text.secondary
          : colors.text.primary,*/
        backgroundColor: $gray ? "#F0F0F0" : "transparent",
        borderBottom: "solid 3px",
        borderColor: error ? colors.error.main : `#000000`,
        "& input": {
          padding: spacing(0.5),
          fontFamily: Fonts.medium,
          fontSize: 16,

          "&::placeholder": {
            opacity: 0.4,
          },
        },
        "& fieldset": {
          border: error ? null : 0,
        },
        ".MuiIconButton-root": {
          color: "black",
          padding: "0px",
        },
        ".MuiInputAdornment-positionEnd": {
          marginLeft: 0,
        },
        ".MuiSvgIcon-root": {
          maxWidth: "18px",
        },
      },
    };
  }
);
const Label = styled(Text)(({ theme, $withValue, $withError }) => {
  const { spacing, palette: colors } = theme;

  return {
    position: "absolute",
    top: $withValue ? 0 : spacing(4.5),
    left: $withValue ? 0 : spacing(1),
    color: $withValue
      ? $withError
        ? colors.error.main
        : "#000000"
      : "#000000",
    transition: ".2s",
    pointerEvents: "none",
    ...FontStyle.semibold14,
  };
});

function InputDateComponent(props) {
  const {
    value,
    error,
    handleKeyPress,
    handleKeyDown,
    onBlur,
    setConfigs,
    ref,
    formHandler,
    usedProps,
  } = formConnector.useStringValue(props);

  const {
    inputLabel,
    icon,
    theme,
    required,
    color,
    disableError,
    borderRadius,
    variant = "outlined",
    inputStyle,
    views = ["year", "month", "date"],
    autoOk = true,
    format,
    readOnly,
    minDate,
    maxDate,
    alternativeColors,
    gray,
    autoComplete,
    defaultMargin,
    placeholder,
    hideVisualError,
    noMargin,
    ...restProps
  } = usedProps;

  return (
    <FormControl fullWidth error={hideVisualError ? null : Boolean(error)}>
      <MuiPickersUtilsProvider utils={dateFns}>
        <InputDate
          noMargin={noMargin}
          placeholder={placeholder}
          {...restProps}
          $alternativeColors={alternativeColors}
          $gray={gray}
          {...(readOnly ? { open: !readOnly } : {})}
          autoComplete={autoComplete === false ? "new-password" : null}
          inputVariant="outlined"
          format={format ? format : "dd/MM/yyyy"}
          minDate={minDate}
          maxDate={maxDate}
          inputProps={{
            readOnly,

            style: {
              padding:
                variant === "outlined" && color === "secondary"
                  ? Spacing(1)
                  : null,
            },
          }}
          value={value ? value : null}
          required={Boolean(required)}
          variant={variant}
          color="black"
          fullWidth
          onKeyPress={handleKeyPress}
          onKeyDown={handleKeyDown}
          views={views}
          inputRef={ref}
          autoOk={autoOk}
          onChange={(date) => {
            setConfigs(date);
          }}
          onBlur={(event) => {
            setTimeout(() => {
              onBlur(event);
              formHandler.testInputError(props.name);
            }, 10);
          }}
          error={Boolean(error)}
          helperText={disableError ? null : error ? error : " "}
        />
        {inputLabel && (
          <Label $withValue={true} $withError={Boolean(error)}>
            {inputLabel}
          </Label>
        )}
      </MuiPickersUtilsProvider>
    </FormControl>
  );
}

export default InputDateComponent;
