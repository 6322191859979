import React from "react";
import Styles from "../styles/StyledDetails";
import { Colors, Texts } from "../../../../config";
import { Grid } from "@material-ui/core";

import DropdownActionSelect from "../../../../components/buttons/DropdownActionSelect";
import { ArrowDropDown } from "@material-ui/icons";
import { customModal } from "../../../modals/utils";
import { FormHolder } from "../../../../FormConfig";

function CurrentReservationInput({
  studioListData,
  BehomeChangeAvaliable,
  BehomeChangeCleaning,
  isFetching,
}) {
  const texts = Texts["pt-BR"].beHome.StudioDetailsList;

  const AvaliableStatusChangeModal = () => {
    customModal.setInfos(
      null,
      texts.actionModal.title,
      null,
      null,
      null,
      <DrawAvaliableModal />
    );
  };

  const sendChangeAvaliable = () => {
    BehomeChangeAvaliable(studioListData.id);
    customModal.close();
  };

  const DrawAvaliableModal = () => {
    return (
      <Styles.Content $defaultContent>
        <FormHolder onSubmit={sendChangeAvaliable}>
          <Styles.HeaderContainer $defaultHeader>
            <Styles.SubTitle>
              {texts.actionModal.text}
              <span style={{ color: Colors.green }}>{"Disponível"}</span> {"?"}
            </Styles.SubTitle>
          </Styles.HeaderContainer>

          <Grid container spacing={0} direction="row" justify="flex-end">
            <Grid item xs={12} sm={6} md={2} lg={2}>
              <Styles.TextButton
                $defaultBackButtom
                onClick={() => customModal.close()}
              >
                {texts.actionModal.back}
              </Styles.TextButton>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Styles.TextButton
                name="button"
                type="submit"
                loading={isFetching}
                disabled={isFetching}
              >
                {texts.actionModal.confirm}
              </Styles.TextButton>
            </Grid>
          </Grid>
        </FormHolder>
      </Styles.Content>
    );
  };

  const ChangeStatusCleaningModal = () => {
    customModal.setInfos(
      null,
      texts.actionModal.title,
      null,
      null,
      null,
      <DrawCleaningModal />
    );
  };

  const sendChangeCleaning = () => {
    BehomeChangeCleaning(studioListData.id);
    customModal.close();
  };

  const DrawCleaningModal = () => {
    return (
      <Styles.Content $defaultContent>
        <FormHolder onSubmit={sendChangeCleaning}>
          <Styles.HeaderContainer $defaultHeader>
            <Styles.SubTitle>
              {texts.actionModal.text}
              <span style={{ color: Colors.blue }}>{"Em Limpeza"}</span> {"?"}
            </Styles.SubTitle>
          </Styles.HeaderContainer>

          <Grid container spacing={0} direction="row" justify="flex-end">
            <Grid item xs={12} sm={6} md={2} lg={2}>
              <Styles.TextButton
                $defaultBackButtom
                onClick={() => customModal.close()}
              >
                {texts.actionModal.back}
              </Styles.TextButton>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Styles.TextButton
                name="button"
                type="submit"
                loading={isFetching}
                disabled={isFetching}
              >
                {texts.actionModal.confirm}
              </Styles.TextButton>
            </Grid>
          </Grid>
        </FormHolder>
      </Styles.Content>
    );
  };

  return (
    <>
      {/*<Grid container spacing={2} direction="row">
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <ContainerInputs
            setBorder
            inputLabel={
              <Styles.InputTitle>{texts.form.reservation[0]}</Styles.InputTitle>
            }
            children={
              <Styles.TextInput
                name="username"
                defaultValue={"Afonso Mathias Santos Jr"}
                disableError
                readOnly
              />
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3}>
          <ContainerInputs
            setBorder
            inputLabel={
              <Styles.InputTitle>{texts.form.reservation[1]}</Styles.InputTitle>
            }
            children={
              <Styles.TextInput
                name="checkIn"
                defaultValue={"15/03/2022"}
                disableError
                readOnly
              />
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3}>
          <ContainerInputs
            setBorder
            inputLabel={
              <Styles.InputTitle>{texts.form.reservation[2]}</Styles.InputTitle>
            }
            children={
              <Styles.TextInput
                name="checkOut"
                defaultValue={"20/03/2022"}
                disableError
                readOnly
              />
            }
          />
        </Grid>
          </Grid>*/}
      <Styles.SelectContainer>
        <Styles.SelectContent>
          <Styles.SelectText>{texts.actionButton}</Styles.SelectText>
          <DropdownActionSelect
            customIcon={<ArrowDropDown style={{ fill: "#FFF" }} />}
            options={[
              {
                action: AvaliableStatusChangeModal,
                name: texts.actionModal.modalName[0],
              },
              {
                action: ChangeStatusCleaningModal,
                name: texts.actionModal.modalName[1],
              },
            ]}
          />
        </Styles.SelectContent>
      </Styles.SelectContainer>
    </>
  );
}

export default CurrentReservationInput;
