import React from "react";
import { Principal } from "../features";

function UsersPage() {
  return (
    <>
      <Principal.Users.Container />
    </>
  );
}

export default UsersPage;
