import React from "react";
import { BeWork } from "../features";

function BeWorkHistoricRelease() {
  return (
    <>
      <BeWork.HistoricReleaseTable.Container />
    </>
  );
}

export default BeWorkHistoricRelease;
