import React from "react";
import { Texts } from "../../../../config";
import Styles from "../styles/Styles";
import { InputAutoComplete } from "../../../../components";
import { FormHolder } from "../../../../FormConfig";

function VerticalFilters({ name, sendParameters, options }) {
  const Title = Texts["pt-BR"].cashBack;

  const onChangeVerticalSector = (e, newInputValue) => {
    sendParameters(newInputValue);
  };

  return (
    <>
      <FormHolder>
        <Styles.FilterContainer>
          <Styles.FilterTitle>{Title.verticalFilters}</Styles.FilterTitle>

          <InputAutoComplete
            name={name}
            onChange={onChangeVerticalSector}
            options={options}
            disableError
          />
        </Styles.FilterContainer>
      </FormHolder>
    </>
  );
}

export default VerticalFilters;
