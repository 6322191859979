export default function getBehomeStudioListData(response) {
  if (response.status === 204) {
    return { content: [], totalPages: 0 };
  }

  const data = response.data.content.map((item) => {
    return {
      id: item.id,
      studioName: item.name, //nameContent
      studioType: item.category.name,
      status: item.status,
      occupied: item.occupied,
      externalId: item.externalId,
      stayType:
        item.stayType === "S"
          ? "Hospedagem"
          : item.stayType === "L"
          ? "Moradia"
          : null,
      category: {
        price: item.category.price,
        size: item.category.size,
        description: item.category.description,
        stays: item.category.stays,
      },
    };
  });

  return {
    content: data,
    totalPages: response.data.totalPages,
  };
}
