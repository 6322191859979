import styled from "styled-components";
import { Fonts } from "../../../../config";
import { CircularProgress, IconButton, makeStyles } from "@material-ui/core";
import FontStyles from "../../../../components/styles/fontsStyles";
import { ButtonContained, ButtonText, Text } from "../../../../components";
import { Colors } from "../../../../config";

const Container = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    padding: spacing(2.75),
    background: Colors.white0,
    width: "100%",
    height: "100%",
  };
});
const ScrollContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    overflow: "auto",
    paddingRight: spacing(1),
    height: "73vh",
    width: "100%",
  };
});
const HeaderContainer = styled.div(() => {
  return {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "solid 2px",
    color: Colors.black,
  };
});

const HeaderContent = styled.div(() => {
  return {
    display: "flex",
    alignItems: "center",
    width: "250px",
  };
});

const Title = styled(Text)(() => {
  return {
    color: Colors.black,
    ...FontStyles.bold14,
  };
});

const FilterContainer = styled.div(() => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  };
});

export const FilterTitle = styled(Text)(({ theme }) => {
  const { spacing } = theme;
  return {
    paddingTop: spacing(2),
    paddingBottom: spacing(1),
    fontFamily: Fonts.semibold,
    fontSize: 12,
    color: Colors.black,
  };
});

const LoadingContainer = styled.div(() => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    margin: "auto",
  };
});

const CircleLoading = styled(CircularProgress)(() => {
  return {
    "&.MuiCircularProgress-indeterminate": {
      color: Colors.black,
    },
  };
});
const Label = styled(Text)(({ theme, $withValue, $withError }) => {
  const { palette: colors } = theme;

  return {
    color: $withValue
      ? $withError
        ? colors.error.main
        : "#000000"
      : "#000000",
    transition: ".2s",
    pointerEvents: "none",
    ...FontStyles.semibold14,
    paddingBottom: "2px",
  };
});

const ButtonFilter = styled(ButtonContained)(
  ({ theme, $withValue, $withError }) => {
    const { spacing } = theme;

    return {
      borderRadius: 0,
      paddingBlock: spacing(2),
      paddingInline: spacing(2.75),
      ...FontStyles.semibold14,
      textTransform: "none",
      backgroundColor: "black",
    };
  }
);
const ButtonClear = styled(ButtonText)(({ theme, $withValue, $withError }) => {
  const { spacing } = theme;

  return {
    borderRadius: 0,
    paddingBlock: spacing(2),
    paddingInline: spacing(2.75),
    ...FontStyles.semibold14,
    textTransform: "none",
  };
});

const Status = styled.div(({ theme, check }) => {
  const { spacing } = theme;
  return {
    width: spacing(2.25),
    height: spacing(2.25),
    backgroundColor: check ? "#6CCE65" : "#DB3E3E",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
});
const OpenText = styled.a(({ theme, $withValue, $withError }) => {
  return {
    borderRadius: 0,
    padding: 0,
    ...FontStyles.semibold10,
    display: "flex",
  };
});

const Modal = styled.div(({ theme, check }) => {
  const { spacing } = theme;
  return {
    paddingInline: spacing(2),
  };
});

const EditButton = styled(IconButton)(({ theme, check }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "#000000",
    color: "white",
    fontSize: spacing(1.5),
    width: spacing(1.55),
    height: spacing(1.55),
    padding: 0,
    borderRadius: 0,
    padding: spacing(0.5),

    marginLeft: spacing(0.8),
    ":hover": {
      backgroundColor: "#00000060",
    },
    "& .MuiSvgIcon-root": {
      fontSize: spacing(1.25),
    },
  };
});

const useStyles = makeStyles((theme) => {
  return {
    inputNormal: {
      color: "black",

      backgroundColor: "rgba(27, 30, 34, 0.30)",
      border: "1px solid rgba(0, 0, 0, 0.10)",
    },

    inputDisabled: {
      color: "black",

      backgroundColor: "rgba(27, 30, 34, 0.30)",
      border: "1px solid rgba(0, 0, 0, 0.10)",
    },
  };
});
const Styles = {
  HeaderContainer,
  HeaderContent,
  Container,
  LoadingContainer,
  Title,
  FilterContainer,
  FilterTitle,
  CircleLoading,
  ScrollContainer,
  Label,
  ButtonFilter,
  ButtonClear,
  Status,
  OpenText,
  Modal,
  EditButton,
  useStyles,
};

export default Styles;
