import React from "react";
import Styles from "../styles/Styles";

import { FormHelperText, Grid } from "@material-ui/core";

import { CustomText, Input, InputDate, Select } from "../../../../components";
import fonts from "../../../../config/fonts";
import { FormHolder } from "../../../../FormConfig";
import filters from "../../../../lib/Filters";
import validations from "../../../../lib/Validations";

function FiltersComponent({ submitFilter, isFetching }) {
  const [minDate, setMinDate] = React.useState(null);
  const [formRef, setFormRef] = React.useState(null);
  const [timeError, setTimeError] = React.useState(false);
  const customDateValid = (data, maxDate, minDate) => {
    const error = validations.inputDate(data, maxDate, minDate);

    if (Boolean(error?.isValid)) {
      setTimeError(error);
    } else {
      setTimeError(false);
    }
    return error;
  };
  return (
    <Styles.Container>
      <CustomText fontFamily={fonts.bold} fontSize={2}>
        Filtrar Reservas
      </CustomText>
      <FormHolder
        alignItems="flex-end"
        formRef={setFormRef}
        onSubmit={submitFilter}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Input
              name="name"
              inputLabel="Nome do Hóspede"
              placeholder="Nome do Hóspede"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Input
              name="phone"
              inputLabel="Telefone"
              placeholder="+0000 (000) 0 0000-0000"
              maskToSubmit={filters.removeNumberMask}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Input
              type="email"
              name="email"
              inputLabel="E-mail"
              placeholder="email@exemplo.com"
              maskToSubmit={filters.toLowerCaseFilter}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <InputDate
              placeholder="00/00/0000"
              inputLabel="Data Criação da Reserva"
              name="createdAt"
              customValidation={customDateValid}
              maxDate={new Date()}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <InputDate
              placeholder="00/00/0000"
              inputLabel="Check-in"
              name="checkInDate"
              customValidation={customDateValid}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <InputDate
              placeholder="00/00/0000"
              inputLabel="Check-out"
              name="checkOutDate"
              customValidation={customDateValid}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Input
              name="reservationCode"
              inputLabel="Código de Reserva"
              placeholder="Código de Reserva"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Select
              inputLabel="Status"
              name="reservationConfirmed"
              defaultValue="all"
              options={[
                { label: "Todos", value: "all" },
                { label: "Pendente", value: false },
                { label: "Preenchido", value: true },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <div
              style={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <Styles.ButtonClear
                type="clearDefault"
                fullWidth={false}
                loading={isFetching}
                disabled={isFetching}
              >
                Limpar
              </Styles.ButtonClear>
              <Styles.ButtonFilter
                fullWidth={false}
                loading={isFetching}
                disabled={isFetching}
                onClick={async () => {
                  const submitInfo = await formRef?.testErrorsAndReturnData();
                  const startError = await formRef?.testInputError("startDate");
                  const endError = await formRef?.testInputError("endDate");

                  if (startError.error || endError.error) {
                    setTimeError(startError.error ? startError : endError);
                  } else {
                    setTimeError(false);
                  }

                  if (!submitInfo?.hasError) {
                    formRef?.submit();
                  }
                }}
              >
                Aplicar FIltros
              </Styles.ButtonFilter>
            </div>
          </Grid>
        </Grid>
      </FormHolder>
    </Styles.Container>
  );
}

export default FiltersComponent;
