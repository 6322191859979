import React from "react";
import Styles from "../styles/Styles";
import { Texts } from "../../../../config";
import { Grid } from "@material-ui/core";
import HistoricTable from "../components/HistoricTable";
import { StayTypeFilter } from "../components";
import { IntegratedComponentHolder } from "../../../../components";
import { connect } from "react-redux";
import { Creators } from "../reduxSagas";

function Container({ HistoricListRequest, HistoricData, isFetching }) {
  const texts = Texts["pt-BR"].beHome;
  const [page, setPage] = React.useState(1);
  const [sort, setSort] = React.useState("desc");
  const pageSize = 10;
  const [staySubscriptionType, setStaySubscriptionType] = React.useState("");

  const typeStay = [
    {
      value: 0,
      label: "Todos",
    },
    {
      value: "S",
      label: "Hospedagem",
    },
    {
      value: "L",
      label: "Moradia",
    },
  ];

  const loadTableData = React.useCallback(() => {
    HistoricListRequest(page - 1, [sort], pageSize, staySubscriptionType);
  }, [HistoricListRequest, page, sort, pageSize, staySubscriptionType]);

  React.useEffect(loadTableData, [loadTableData]);

  const handleStayTypeFilter = (value) => {
    if (value === 0) {
      setStaySubscriptionType("");
    } else {
      setStaySubscriptionType(value);
    }
  };

  return (
    <Styles.Container>
      <Styles.HeaderContainer>
        <Styles.Title>{texts.HistoricList.title}</Styles.Title>

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <StayTypeFilter
            name="stayType"
            onChange={handleStayTypeFilter}
            options={typeStay}
            defaultValue={0}
          />
        </Grid>
      </Styles.HeaderContainer>

      <Styles.ScrollContainer>
        {isFetching ? (
          <Styles.LoadingContainer>
            <Styles.CircleLoading
              style={{ width: "10%", height: "auto", margin: "250px" }}
            />
          </Styles.LoadingContainer>
        ) : (
          <IntegratedComponentHolder
            showReload={!HistoricData && !isFetching}
            reloadMessage={texts.notFound.message}
            reloadCallback={HistoricListRequest}
            reloadLabel={texts.notFound.buttonLabel}
            showEmpty={!HistoricData?.content.length && !isFetching}
            emptyMessage={null}
          >
            <HistoricTable
              data={HistoricData}
              loading={isFetching}
              page={page}
              setPage={setPage}
              setSort={setSort}
              sort={sort}
              reload={loadTableData}
            />
          </IntegratedComponentHolder>
        )}
      </Styles.ScrollContainer>
    </Styles.Container>
  );
}

function mapStateToProps(state) {
  const { HistoricData, isFetching } = state.behomeHistoric;
  return {
    isFetching,
    HistoricData,
  };
}
function mapDispatchToProps(dispatch) {
  const { HistoricListRequest } = Creators;
  return {
    HistoricListRequest: function (page, sort, pageSize, staySubscriptionType) {
      return dispatch(
        HistoricListRequest(page, sort, pageSize, staySubscriptionType)
      );
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Container);
