import React from "react";
import { Behome } from "../features";



function BehomeStudiosList() {
  return (
    <>
      <Behome.StudiosListCards.Container />
    </>
  );
}
export default BehomeStudiosList;
