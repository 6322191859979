import React from "react";
import styled from "styled-components";

import { IconButton, Button, Select, MenuItem } from "@material-ui/core";

import { ArrowDropDown, ArrowLeft, ArrowRight } from "@material-ui/icons";

import { FontStyles } from "..";

const BottomNav = styled.div(({ theme, $style }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    justifyContent: "flex-end",
    margin: spacing(1.875, 0),
    alignSelf: "flex-end",
    alignItems: "center",
    ...$style,
  };
});

const PageNav = styled(IconButton)(({ theme, alternative }) => {
  const { spacing } = theme;
  return {
    "&&.MuiIconButton-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: spacing(2),
      height: spacing(2),

      minHeight: spacing(2),
      minWidth: spacing(2),

      backgroundColor: "transparent",
      borderWidth: "0",
      color: "black",
    },
  };
});

const PageList = styled.div(({ theme }) => {
  return {
    display: "flex",
  };
});

const PageListButton = styled(Button)(({ theme, $show, alternative }) => {
  const { spacing } = theme;
  return {
    "&&.MuiButtonBase-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      width: spacing(3),
      height: spacing(3),
      minHeight: spacing(3),
      minWidth: spacing(3),

      margin: spacing(0, 0.125),

      color: $show ? "white" : "black",
      borderRadius: 0,
      backgroundColor: $show ? "black" : "transparent",
      border: $show ? "none" : `2px solid #000000`,
      ...FontStyles.semibold10,
    },
  };
});

const PageSelectText = styled.p(({ theme, alternative }) => {
  const { spacing } = theme;
  return {
    alignSelf: "center",
    ...FontStyles.semibold10,
    margin: 0,
    marginInline: spacing(1.2),
    color: alternative ? "white" : theme.palette.text.primary,
  };
});

const PageSelect = styled(Select)(({ theme, alternative }) => {
  const { spacing } = theme;
  return {
    "&&.MuiInputBase-root": {
      width: "3.75rem",
      height: "1.75rem",
      padding: spacing(0.5, 1.25),
      //marginRight: spacing(1.875),
      color: "white",
      backgroundColor: "black",
      borderRadius: 0,
      ".MuiFilledInput-input": {
        "$hover $notchedOutline": { borderColor: "orange" },
        padding: 0,
        ...FontStyles.semibold10,
        color: "white",
      },
      ".MuiSelect-icon": {
        alignSelf: "center",
        width: spacing(2),
        height: spacing(2),
        top: "6px",
        color: "white",
        fill: "white",
      },
    },
  };
});

const ItemMenu = styled(MenuItem)(({ theme, $color }) => {
  const { spacing } = theme;
  return {
    width: "5.25rem",
    height: "1.75rem",
    padding: spacing(0.75, 1.875),
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  };
});

const PagContainer = styled.div(({ theme, $color }) => {
  return {
    display: "flex",
    flexDirection: "row",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  };
});
function PaginationComponent({
  page,
  setPage,
  totalPages,
  style,
  alternative,
}) {
  return (
    <BottomNav $style={style}>
      <PageNav
        alternative={alternative}
        onClick={() => {
          backPage(page, setPage);
        }}
      >
        <ArrowLeft />
      </PageNav>
      <PageList>
        {getPaginationContent(setPage, totalPages, page, alternative)}
      </PageList>
      <PageNav
        alternative={alternative}
        onClick={() => {
          nextPage(page, totalPages, setPage);
        }}
      >
        <ArrowRight />
      </PageNav>
      <PagContainer>
        <PageSelectText alternative={alternative}>Ir para: </PageSelectText>
        <PageSelect
          alternative={alternative}
          variant="filled"
          labelId="Seleção-da-pagina"
          id="Seleção-da-pagina"
          value={page}
          onChange={(value) => {
            setPage(value.target.value);
          }}
          IconComponent={ArrowDropDown}
          disableUnderline={true}
          inputProps={{ disableUnderline: true }}
          label="Pagina"
        >
          {checkTotalPages(totalPages, page, alternative)}
        </PageSelect>
      </PagContainer>
    </BottomNav>
  );
}

export default React.memo(PaginationComponent);

const checkTotalPages = (totalPages, page, alternative) => {
  let components = [];
  for (let i = 0; i < totalPages; i++) {
    components.push(
      <ItemMenu
        alternative={alternative}
        key={"key_" + (i + 1)}
        selected={i === page}
        value={i + 1}
      >
        {i + 1}
      </ItemMenu>
    );
  }

  return components;
};
const getPaginationContent = (setPage, totalPages, page, alternative) => {
  let content = [];
  const changePageTo = (toPage) => {
    setPage(toPage);
  };
  for (let i = 0; i < totalPages; i++) {
    if (page > 3 && page < totalPages - 4) {
      if (i >= page - 3 && i <= page + 1) {
        content.push(
          <PageListButton
            alternative={alternative}
            key={"keyPage_" + (i + 1)}
            $show={i + 1 === page ? true : false}
            onClick={() => changePageTo(i + 1)}
          >
            {" "}
            {i + 1}{" "}
          </PageListButton>
        );
      }
      if (i === page + 3) {
        content.push(
          <PageListButton
            alternative={alternative}
            key={"keyPage_" + (i + 1)}
            $show={i + 1 === page ? true : false}
            onClick={() => changePageTo(totalPages)}
          >
            {"..."}
          </PageListButton>
        );
      }
    } else if (page < 4) {
      if (i <= 5) {
        content.push(
          <PageListButton
            alternative={alternative}
            key={"keyPage_" + (i + 1)}
            $show={i + 1 === page ? true : false}
            onClick={() => changePageTo(i + 1)}
          >
            {" "}
            {i + 1}{" "}
          </PageListButton>
        );
      }
    } else if (i >= totalPages - 6) {
      content.push(
        <PageListButton
          alternative={alternative}
          key={"keyPage_" + (i + 1)}
          $show={i + 1 === page ? true : false}
          onClick={() => changePageTo(i + 1)}
        >
          {" "}
          {i + 1}{" "}
        </PageListButton>
      );
    }
  }
  return content;
};

function nextPage(page, totalPages, setPage) {
  if (page !== totalPages) {
    setPage(page + 1);
  }
}

function backPage(page, setPage) {
  if (page > 1) {
    setPage(page - 1);
  }
}
