import { Filters } from "../../lib";
import {
  createBarScalesX,
  createBarScalesXMobile,
  createLayoutWithDatalabels,
  createLayoutWithDatalabelsMobile,
} from "./CommonChartConfigs";

function extractLastTwo(response) {
  const labels = response.labels || [];
  const values = response.values || [];

  const lastTwoLabels = labels.slice(-2);
  const lastTwoValues = values.slice(-2);

  const result = lastTwoLabels.map((label, index) => ({
    label: label,
    value: lastTwoValues[index],
  }));

  return result;
}

export default function getLineChartMobile(response, withLine) {
  const cores = ["#00796D54"];
  const coresBorda = ["#00796D"];
  const lastTwoData = extractLastTwo(response);
  const chartData = {
    type: "line",
    data: {
      labels: response.labels,
      datasets: [
        {
          label: "Teste2",
          type: "line",
          data: response.values,
          backgroundColor: response?.colors
            ? response?.colors.background
            : cores[0],
          borderColor: response?.colors ? response?.colors.main : coresBorda[0],
          responsive: true,
          maintainAspectRatio: false,
          fill: true,
          lineTension: 0,
          borderWidth: 2,
          pointRadius: 5,
          pointBorderWidth: 0,
          pointBackgroundColor: response?.colors
            ? response?.colors.background
            : cores[0],
          pointBorderColor: response?.colors
            ? response?.colors.main
            : coresBorda[0],
        },
      ],
    },
    plugins: withLine
      ? [
          {
            beforeDraw: function (chart) {
              if (typeof chart.config.options.lineAverage !== "undefined") {
                var lineAverage = chart.config.options.lineAverage;

                var ctxPlugin = chart.chart.ctx;
                var xAxe =
                  chart.scales[chart.config.options.scales.xAxes[0].id];
                var yAxe =
                  chart.scales[chart.config.options.scales.yAxes[0].id];
                if (yAxe.min !== 0) return;

                ctxPlugin.strokeStyle = "#000000";
                ctxPlugin.lineWidth = 2; // Define a espessura da linha para 3px
                ctxPlugin.beginPath();
                lineAverage = (lineAverage - yAxe.min) * (100 / yAxe.max);
                lineAverage =
                  ((100 - lineAverage) / 100) * yAxe.height + yAxe.top;
                ctxPlugin.moveTo(xAxe.left, lineAverage);
                ctxPlugin.lineTo(xAxe.right + 20, lineAverage);
                ctxPlugin.setLineDash([0]);
                ctxPlugin.stroke();
              }
            },
          },
        ]
      : [],
    options: {
      type: response?.type,
      lineAverage: response?.average,
      lastTwo: lastTwoData,
      interaction: {
        mode: "dataset",
      },
      responsive: true,
      maintainAspectRatio: false,
      labels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: true,
        mode: "single",
        backgroundColor: "white", // Use a mesma cor de fundo que os datalabels
        borderColor: "#00000012", // Use a mesma cor da borda que os datalabels
        borderWidth: 2, // Use a mesma largura de borda que os datalabels
        titleFontFamily: "Montserrat", // Use a mesma família de fonte que os datalabels
        titleFontSize: 12, // Use o mesmo tamanho de fonte que os datalabels
        titleFontColor: "#464646", // Use a mesma cor de fonte que os datalabels
        bodyFontFamily: "Montserrat", // Use a mesma família de fonte que os datalabels
        bodyFontSize: 12, // Use o mesmo tamanho de fonte que os datalabels
        bodyFontColor: "#464646", // Use a mesma cor de fonte que os datalabels
        displayColors: false,
        callbacks: {
          title: function () {
            return ""; // Retorna uma string vazia para remover o título
          },
          displayColors: false,
          label: function (tooltipItem, data) {
            let valueData = "";

            valueData = Filters.convertNumberInputMask(
              tooltipItem.yLabel.toFixed(2),
              response.type === "money" ? true : false,
              true
            );

            return response.type === "percent" ? valueData + "%" : valueData;
          },
        },
      },

      layout: createLayoutWithDatalabelsMobile(),
      scales: createBarScalesXMobile(
        "#46464628",
        "#464646",
        false,
        undefined,
        8,
        response.type
      ),
    },
  };

  return chartData;
}
