import { call, put } from "redux-saga/effects";
import { ResponseError, api } from "../../../services";
import { Creators } from "./index";
import { Creators as globalCreators } from "../../globalReduxSagas";
import { Alerts, LocalStorage, SessionStorage } from "../../../lib";
import jwtDecode from "jwt-decode";
import errorResponseMessage from "../../../services/helpers/getErrorMessage";

export default function* loginRequest({ data }) {
  try {
    yield call(api.getToken, {
      password: data?.password,
      username: data?.username,
    });

    const token = jwtDecode(SessionStorage.getItem("token"));

    if (token.ROLES.includes("ROLE_ADMIN")) {
      yield put(Creators.loginSuccess());

      if (data?.remember) {
        const responseRefreshToken = yield call(api.getRefresh, {
          password: data?.password,
        });

        if (responseRefreshToken.ok) {
          const refreshToken = responseRefreshToken.data.token;
          yield LocalStorage.setItem("refreshToken", refreshToken);
          yield LocalStorage.setItem("lastEmail", data?.username);
          SessionStorage.setItem("isLoggedIn", true);
        } else {
          const errorResponseToken = new ResponseError(responseRefreshToken);
          errorResponseToken.alertMessage();
          yield put(Creators.loginFailure());
        }
      } else {
        SessionStorage.setItem("isLoggedIn", true);
      }

      yield put(globalCreators.getGlobalStatus());
    } else {
      Alerts.alertError(errorResponseMessage({ problem: "NOT_ADMIN" }));
      yield put(Creators.loginFailure());
    }
  } catch (response) {
    const errorResponse = new ResponseError(response);
    errorResponse.alertMessage();
    yield put(Creators.loginFailure());
  }
}
