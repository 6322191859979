import createReducers from "../../../../store/helpers/createPageReducer";
import HistoricListRequest from "./HistoricListRequest";
import BeHomeHistoricCategoryRequest from "./BeHomeHistoricCategoryRequest";
import BehomeHistoricStayRequest from "./BehomeHistoricStayRequest";
import BehomeHistoricTransfer from "./BehomeHistoricTransfer";
import BehomeHistoricCancel from "./BehomeHistoricCancel";
import GuestRecordDetailsRequest from "./GuestRecordDetailsRequest";
import GuestPictureDocumentRequest from "./GuestPictureDocumentRequest";
import ReverseReservation from "./ReverseReservation";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "HistoricListRequest",
      params: ["page", "sort", "pageSize", "staySubscriptionType"],
      function: (state, { data }) => ({
        ...state,
        isFetching: true,
        HistoricData: null,
      }),
      sagaFunction: HistoricListRequest,
    },

    {
      name: "HistoricListRequestSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        HistoricData: data,
      }),
    },

    {
      name: "ReverseReservation",
      params: ["behomeShortStaySubscriptionId"],
      function: (state, { data }) => ({
        ...state,
      }),
      sagaFunction: ReverseReservation,
    },

    {
      name: "BehomeHistoricTransfer",
      params: ["stayType", "StaySubscriptionId", "BehomeStayId"],
      function: (state, { data }) => ({
        ...state,
      }),
      sagaFunction: BehomeHistoricTransfer,
    },

    {
      name: "BehomeHistoricCancel",
      params: ["stayType", "StaySubscriptionId", "data"],
      function: (state, { data }) => ({
        ...state,
      }),
      sagaFunction: BehomeHistoricCancel,
    },

    {
      name: "BeHomeHistoricCategoryRequest",
      params: ["stayType"],
      function: (state, { data }) => ({
        ...state,
      }),
      sagaFunction: BeHomeHistoricCategoryRequest,
    },

    {
      name: "BeHomeHistoricCategoryRequestSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        StayCategorytData: data,
      }),
    },

    {
      name: "BehomeHistoricStayRequest",
      params: ["behomeStayId"],
      function: (state) => ({
        ...state,
      }),
      sagaFunction: BehomeHistoricStayRequest,
    },

    {
      name: "BehomeHistoricStayRequestSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        StayRoomData: data,
      }),
    },

    {
      name: "HistoricListRequestFinish",
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },

    {
      name: "HistoricListRequestFailure",
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },

    {
      name: "GuestRecordDetailsRequest",
      params: ["checkInId"],
      function: (state, { data }) => ({
        ...state,
      }),
      sagaFunction: GuestRecordDetailsRequest,
    },

    {
      name: "GuestRecordDetailsRequestSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        GuestRecordDetailsData: data,
      }),
    },

    {
      name: "GuestRecordDetailsRequestFailure",
      function: (state) => ({
        ...state,
      }),
    },

    {
      name: "GuestPictureDocumentRequest",
      params: ["imageIds"],
      function: (state, { data }) => ({
        ...state,
      }),
      sagaFunction: GuestPictureDocumentRequest,
    },

    {
      name: "GuestPictureDocumentRequestSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        GuestPictureDocumentData: data,
      }),
    },

    {
      name: "GuestPictureDocumentRequestFailure",
      function: (state) => ({
        ...state,
      }),
    },
  ],
  {
    isFetching: false,
    HistoricData: null,
    StayCategorytData: [],
    GuestPictureDocumentData: null,
    GuestRecordDetailsData: [],
  }
);

export { Creators, reducers, sagas };
