import createReducers from "../../../store/helpers/createPageReducer";
import DashDailyAverageGraph from "./DashDailyAverageGraph";
import DashDailyAverageValueGraph from "./DashDailyAverageValueGraph";
import DashDailyAverageValue from "./DashDailyAverageValueRequest";

import DashRequest from "./DashRequest";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "dashRequest",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: true,
        dataDailyAverage: null,
        lastFilter: data,
      }),
      sagaFunction: DashRequest,
    },

    {
      name: "dashRequestSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        dataDailyAverage: data,
      }),
    },

    {
      name: "dashRequestFailure",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        dataDailyAverage: null,
      }),
    },

    {
      name: "dashDailyAverageGraph",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: true,
        dataDailyAverageGraph: null,
        lastFilter: data,
      }),
      sagaFunction: DashDailyAverageGraph,
    },

    {
      name: "dashDailyAverageGraphSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        dataDailyAverageGraph: data,
      }),
    },

    {
      name: "dashDailyAverageGraphFailure",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        dataDailyAverageGraph: null,
      }),
    },

    {
      name: "dashDailyAverageValue",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: true,
        dataDailyAverageValue: null,
        lastFilter: data,
      }),
      sagaFunction: DashDailyAverageValue,
    },

    {
      name: "dashDailyAverageValueRequestSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        dataDailyAverageValue: data,
      }),
    },

    {
      name: "dashDailyAverageValueRequestFailure",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        dataDailyAverageValue: null,
      }),
    },

    {
      name: "dashDailyAverageValueGraph",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: true,
        dataDailyAverageValueGraph: null,
        lastFilter: data,
      }),
      sagaFunction: DashDailyAverageValueGraph,
    },

    {
      name: "dashDailyAverageValueGraphSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        dataDailyAverageValueGraph: data,
      }),
    },

    {
      name: "dashDailyAverageValueGraphFailure",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        dataDailyAverageValueGraph: null,
      }),
    },
  ],
  {
    isFetching: false,
    dataDailyAverage: null,
    dataDailyAverageValue: null,
    dataDailyAverageGraph: null,
    dataDailyAverageValueGraph: null,
    lastFilter: null,
  }
);

export { Creators, reducers, sagas };
