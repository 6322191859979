import { Filters } from "../../lib";
import {
  createBarScalesX,
  createLayoutWithDatalabels,
} from "./CommonChartConfigs";

export default function getLineChart(response) {
  const cores = ["#00796D54"];
  const coresBorda = ["#00796D"];
  const chartData = {
    type: "line",
    data: {
      labels: response.labels,
      datasets: [
        {
          label: "Teste2",
          type: "line",
          data: response.values,
          backgroundColor: response?.colors
            ? response?.colors.background
            : cores[0],
          borderColor: response?.colors ? response?.colors.main : coresBorda[0],
          responsive: true,
          maintainAspectRatio: false,
          fill: true,
          lineTension: 0,
          borderWidth: 2,
          pointRadius: 2,
          pointBorderWidth: 0,
          pointBackgroundColor: response?.colors
            ? response?.colors.background
            : cores[0],
          pointBorderColor: response?.colors
            ? response?.colors.main
            : coresBorda[0],
        },
      ],
    },
    plugins: [
      {
        beforeDraw: function (chart) {
          if (typeof chart.config.options.lineAverage !== "undefined") {
            var lineAverage = chart.config.options.lineAverage;
            var lineAverageTxt = chart.config.options.lineAverage;
            var ctxPlugin = chart.chart.ctx;
            var xAxe = chart.scales[chart.config.options.scales.xAxes[0].id];
            var yAxe = chart.scales[chart.config.options.scales.yAxes[0].id];
            if (yAxe.min !== 0) return;

            ctxPlugin.strokeStyle = "#000000";
            ctxPlugin.lineWidth = 2; // Define a espessura da linha para 3px
            ctxPlugin.beginPath();
            lineAverage = (lineAverage - yAxe.min) * (100 / yAxe.max);
            lineAverage = ((100 - lineAverage) / 100) * yAxe.height + yAxe.top;
            ctxPlugin.moveTo(xAxe.left, lineAverage);
            ctxPlugin.lineTo(xAxe.right + 20, lineAverage);
            ctxPlugin.setLineDash([0]);
            ctxPlugin.stroke();

            // Adiciona um quadrado ajustado ao tamanho do texto com padding de 4px
            var text =
              chart.config.options.type === "percent"
                ? lineAverageTxt.toFixed(1) + "%"
                : chart.config.options.type === "money"
                ? "R$ " + lineAverageTxt.toFixed(1)
                : lineAverageTxt.toFixed(1);
            var padding = 4;
            ctxPlugin.font = "8px Gilroy Semibold"; // Certifique-se de que o tamanho da fonte esteja definido corretamente
            var textWidth = ctxPlugin.measureText(text).width;
            var textHeight = 8; // Altura aproximada da fonte
            var rectWidth = textWidth + 2 * (padding + 4);
            var rectHeight = textHeight + 2 * padding;

            var rectX = 20 + xAxe.right - rectWidth / 2;
            var rectY = lineAverage - rectHeight / 2;

            // Ajusta a posição do retângulo para não sair dos limites do canvas
            if (rectX + rectWidth > chart.width) {
              rectX = chart.width - rectWidth;
            }
            if (rectX < 0) {
              rectX = 0;
            }
            if (rectY + rectHeight > chart.height) {
              rectY = chart.height - rectHeight;
            }
            if (rectY < 0) {
              rectY = 0;
            }

            // Desenha o retângulo de fundo branco
            ctxPlugin.fillStyle = "#FFFFFF";
            ctxPlugin.fillRect(rectX, rectY, rectWidth, rectHeight);

            // Adiciona a borda preta de 1px
            ctxPlugin.lineWidth = 1;
            ctxPlugin.strokeStyle = "#000000";
            ctxPlugin.strokeRect(rectX, rectY, rectWidth, rectHeight);

            // Desenha o texto
            ctxPlugin.fillStyle = "#000000";
            ctxPlugin.textAlign = "center";
            ctxPlugin.textBaseline = "middle";
            ctxPlugin.fillText(
              text,
              rectX + rectWidth / 2,
              rectY + rectHeight / 2
            );
          }
        },
      },
    ],
    options: {
      type: response?.type,
      lineAverage: response?.average,
      interaction: {
        mode: "dataset",
      },
      responsive: true,
      maintainAspectRatio: false,
      labels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: true,
        mode: "single",
        backgroundColor: "white", // Use a mesma cor de fundo que os datalabels
        borderColor: "#00000012", // Use a mesma cor da borda que os datalabels
        borderWidth: 2, // Use a mesma largura de borda que os datalabels
        titleFontFamily: "Montserrat", // Use a mesma família de fonte que os datalabels
        titleFontSize: 12, // Use o mesmo tamanho de fonte que os datalabels
        titleFontColor: "#464646", // Use a mesma cor de fonte que os datalabels
        bodyFontFamily: "Montserrat", // Use a mesma família de fonte que os datalabels
        bodyFontSize: 12, // Use o mesmo tamanho de fonte que os datalabels
        bodyFontColor: "#464646", // Use a mesma cor de fonte que os datalabels
        displayColors: false,
        callbacks: {
          title: function () {
            return ""; // Retorna uma string vazia para remover o título
          },
          displayColors: false,
          label: function (tooltipItem, data) {
            let valueData = "";

            valueData = Filters.convertNumberInputMask(
              tooltipItem.yLabel.toFixed(2),
              response.type === "money" ? true : false,
              true
            );

            return response.type === "percent" ? valueData + "%" : valueData;
          },
        },
      },

      layout: createLayoutWithDatalabels(),
      scales: createBarScalesX(
        "#46464628",
        "#464646",
        false,
        undefined,
        8,
        response.type
      ),
    },
  };

  return chartData;
}
