function getDailyAverageGraph(response) {
  function formatDate(dateString) {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  }

  if (!response.ok) {
    return response;
  } else {
    const valuesByDate = {};

    response?.data?.forEach(({ value, date }) => {
      if (!valuesByDate[date]) {
        valuesByDate[date] = 0;
      }
      valuesByDate[date] += value;
    });

    // Transformar o objeto em arrays para labels e valores
    const labels = [];
    const values = [];

    Object.keys(valuesByDate).forEach((date) => {
      labels.push(formatDate(date));
      values.push(valuesByDate[date]);
    });

    // Calcular a média dos valores por total de dias
    const totalValues = values.reduce((sum, value) => sum + value, 0);
    const average = totalValues / values.length;

    // Criar o objeto chartData
    const chartData = {
      values,
      labels,
      average,
      type: null, // ou qualquer outro valor padrão que você queira para "type"
    };

    return { ...response, data: chartData };
  }
}

export default getDailyAverageGraph;
