import React from "react";
import {
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  Input,
} from "@material-ui/core";
import { formConnector } from "../../FormConfig";
import styled from "styled-components";
import { Fonts } from "../../config";
import Text from "../strings/Text";
import FontStyle from "../styles/fontsStyles";

const StyledInput = styled(Input)(
  ({
    theme,
    error,
    disabled,
    $inputStyle,
    $error,
    $small,
    $alternativeColors,
    ...rest
  }) => {
    const { spacing, palette: colors } = theme;
    return {
      "&&.MuiSelect-nativeInput": {
        opacity: 1,
        "& .MuiInput-input": {
          color: colors.black,
          fontFamily: Fonts.medium,
          marginBottom: "-7px",
          fontSize: 16,
          "&::placeholder": {
            opacity: 0.8,
          },
        },
        "&::placeholder": {
          opacity: 0.8,
        },
      },

      padding: $small ? spacing(0, 1) : spacing(1),
      paddingTop: 0,
      paddingLeft: 0,
      multiline: true,
      textOverflow: "ellipsis",
      background: "none",
      borderRadius: 0,
      marginTop: spacing(2.9),
      boxShadow: $error
        ? `inset 0 0 0 2px ${colors.error.main}`
        : `inset 0 0 0 ${colors.error.main}`,
      opacity: disabled ? 0.3 : 1,
      borderBottom: "solid 3px",
      borderColor: $error ? colors.error.main : `#000000`,
      "& .MuiInput-input": {
        color: colors.black,
        fontFamily: Fonts.medium,
        marginBottom: "-7px",
        fontSize: 16,
        "&.MuiSelect-nativeInput": {
          opacity: "1",
          "&::placeholder": {
            opacity: "0.9",
          },
        },
        "&::placeholder": {
          opacity: "0.9",
        },
      },
      ...$inputStyle,
    };
  }
);
const StyledFormControl = styled(FormControl)(
  ({ theme, $defaultMarginTop }) => ({
    "label + .MuiInput-formControl": {
      marginTop: $defaultMarginTop ? 0 : 8,
    },
  })
);

const StyledMenuItem = styled(MenuItem)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    backgroundColor: colors.background.paper,
    "&.MuiListItem-button:hover": {
      backgroundColor: colors.action.selected + 50,
    },
    "&.Mui-selected": {
      backgroundColor: colors.action.selected + 100,
    },

    "& .MuiPaper-root": {
      backgroundColor: "red",
    },
  };
});
const Label = styled(Text)(({ theme, $withValue, $withError }) => {
  const { spacing, palette: colors } = theme;

  return {
    position: "absolute",
    top: $withValue ? 0 : spacing(4.5),
    left: $withValue ? 0 : spacing(1),
    color: $withValue
      ? $withError
        ? colors.error.main
        : "#000000"
      : "#000000",
    transition: ".2s",
    pointerEvents: "none",
    ...FontStyle.semibold14,
  };
});
function SelectComponent(props) {
  const { value, error, setConfigs, onBlur, ref, formHandler, usedProps } =
    formConnector.useStringValue(props);
  const { onChange, name, small } = props;
  const onSelectValue = React.useCallback(
    (event) => {
      setConfigs(event.target.value, event);
      try {
        onChange(event.target.value);
      } catch (err) {}
      setTimeout(() => {
        onBlur();
        formHandler.testInputError(name);
      }, 10);
    },
    [setConfigs, onBlur, formHandler, name, onChange]
  );

  const {
    containerStyles,
    required,
    inputLabel,
    options,
    disableError,
    readOnly,
    disableBorder,
    defaultMarginTop,
    placeholder,
  } = usedProps;

  return (
    <StyledFormControl
      $defaultMarginTop={defaultMarginTop}
      style={containerStyles}
      fullWidth
      error={Boolean(error)}
      required={Boolean(required)}
      onMouseUp={(event) => event.stopPropagation()}
    >
      <Select
        disableUnderline={disableBorder}
        readOnly={readOnly}
        ref={ref}
        value={value}
        onChange={onSelectValue}
        onMouseUp={(event) => event.stopPropagation()}
        input={<StyledInput $small={small} />}
      >
        {placeholder && (
          <StyledMenuItem value={""} disabled>
            {placeholder}
          </StyledMenuItem>
        )}

        {options.map((option) => (
          <StyledMenuItem value={option.value}>{option.label}</StyledMenuItem>
        ))}
      </Select>
      {disableError ? null : (
        <FormHelperText>{error ? error : " "}</FormHelperText>
      )}
      <Label $withValue={true} $withError={Boolean(error)}>
        {inputLabel}
      </Label>
    </StyledFormControl>
  );
}

export default SelectComponent;
