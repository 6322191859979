import { call, put } from "redux-saga/effects";
import { ResponseError, api } from "../../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../../lib";
import { Texts } from "../../../../config";

export default function* BehomeChangeStatusAvaliable({ BehomeStayId }) {
  const textAlertSucess = Texts["pt-BR"].beHome.StudioDetailsList;
  try {
    yield call(api.StudiosListTransferAvailable, BehomeStayId);
    Alerts.alertSuccess(textAlertSucess.PopupText);
    yield put(Creators.BehomeStudioListDataGetRequest());
    yield put(Creators.BehomeChangeStatusAvaliableSuccess());
  } catch (response) {
    const errorResponse = new ResponseError(response);
    errorResponse.alertMessage();
    yield put(Creators.BehomeChangeStatusAvaliableFailure());
  }
}
